import { FundingStage } from '../../domains/my-page/types';
import { INVESTMENT_STAGE_NUM_VALUE } from '../constants/common';
import { UserRole } from '../types/users';

export const investmentStageCompareFn = (a: FundingStage, b: FundingStage) =>
    INVESTMENT_STAGE_NUM_VALUE[a] - INVESTMENT_STAGE_NUM_VALUE[b];

export const isMentorUser = (type: UserRole | null) => {
    return type ? type === 'MENTOR_INVESTOR' : false;
};
export const isInvestorUser = (type: UserRole | null) => {
    return type ? type !== 'INVESTEE' : false;
};

export const isInvesteeUser = (type: UserRole | null) => {
    return type ? type === 'INVESTEE' : false;
};
