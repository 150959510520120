import {
    AgreementNotificationSaveRequest,
    AgreementUserSaveRequest,
} from '../../domains/auth/types/signUp';
import { axiosInstance } from '../axiosConfig';
import { CommonResult, ListResult, SingleResult } from '../types/NetworkResult';
import {
    AgreementNotificationSaveListResponse,
    AgreementNotificationSaveSingleResponse,
    AgreementUserSaveResponse,
    ModifyPasswordRequest,
    UserInfoResponse,
} from '../types/users';

export async function updateMemberPhone(
    impVerificationCode: string,
): Promise<CommonResult> {
    const response = await axiosInstance.put('/users', {
        impVerificationCode,
    });
    return response.data;
}

export async function fetchUserInfo(): Promise<UserInfoResponse> {
    const response = await axiosInstance.get('/users/my');
    return response.data;
}

export async function withdrawMember(): Promise<CommonResult> {
    const response = await axiosInstance.delete('/users');

    return response.data;
}

export async function updateProfileImage(
    formData: FormData,
): Promise<CommonResult> {
    // await new Promise((r) => setTimeout(r, 5000));
    const response = await axiosInstance.put('/users/icon', formData, {
        headers: { 'Content-Type': `image/*` },
    });
    return response.data;
}

export async function modifyPassword(
    modifyPasswordRequest: ModifyPasswordRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.put(
        '/users/password',
        modifyPasswordRequest,
    );
    return response.data;
}

export async function modifyUserAgreement(
    agreementUserSaveRequest: AgreementUserSaveRequest,
): Promise<SingleResult<boolean>> {
    const response = await axiosInstance.put(
        '/users/agreement',
        agreementUserSaveRequest,
    );
    return response.data;
}

export async function fetchUserAgreement(): Promise<AgreementUserSaveResponse> {
    const response = await axiosInstance.get('/users/agreement');
    return response.data;
}

export async function modifyUserNotificationAgreement(
    agreementNotificationSaveRequest: AgreementNotificationSaveRequest,
): Promise<AgreementNotificationSaveSingleResponse> {
    const response = await axiosInstance.put(
        '/users/notifications',
        agreementNotificationSaveRequest,
    );
    return response.data;
}

export async function fetchUserNotificationAgreement(): Promise<AgreementNotificationSaveListResponse> {
    const response = await axiosInstance.get('/users/notifications');
    return response.data;
}
