import React from 'react';
import { useQuery } from 'react-query';
import { fetchMyInvestorProfile } from '../../../../api/investor';
import queryKeyWithMember from '../../../../shared/constants/queryKeyWithMember';
import { CustomQueryOptions } from '../../../../api/types/CustomQueryOptions';
import { InvestorInfo } from '../../types';
import { SingleResult } from '../../../../api/types/NetworkResult';

export const useMyInvestorProfileQuery = (
    options: CustomQueryOptions<SingleResult<InvestorInfo>>,
) =>
    useQuery(queryKeyWithMember.myProfile.key, fetchMyInvestorProfile, options);

export default useMyInvestorProfileQuery;
