import ErrorMessage from '../constants/ErrorMessage';

export function makeErrorStatement(status: number) {
    switch (status) {
        case 400:
            return ErrorMessage.BAD_REQUEST;
        case 401:
            return ErrorMessage.UNAUTHORIZED_ERROR_MESSAGE;
        case 403:
            return ErrorMessage.FORBIDDEN_ERROR_MESSAGE;
        case 404:
            return ErrorMessage.NOT_FOUND_MESSAGE;
        case 500:
            return ErrorMessage.INTERNAL_SERVER_ERROR_MESSAGE;
        default:
            return ErrorMessage.INTERNAL_SERVER_ERROR_MESSAGE;
    }
}
