import { atom } from 'recoil';
import { atomKey } from '../../utils/recoilUtils';
import { InvestorInfo } from '../../../domains/my-page/types';

export const initInvestorInfoState: InvestorInfo = {
    idx: 0,
    responsibility: '',
    businessCategories: [],
    stages: [],
    email: '',
    name: '',
    biography: '',
    investment: { idx: 0, name: '', description: '', country: undefined },
    types: [],
    locations: [],
    snsList: [],
    iconFullPath: '',
};

export const investorInfoState = atom<InvestorInfo>({
    key: atomKey('investorInfoState'),
    default: initInvestorInfoState,
});
