import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import DeferredComponent from './DeferredComponent';

function Loading() {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 48,
                color: '#0068ff',
                position: 'fixed',
                top: '50%',
                left: '50%',
                zIndex: 100001,
            }}
            spin
        />
    );
    return (
        <DeferredComponent delayMs={200}>
            <Spin indicator={antIcon} />
        </DeferredComponent>
    );
}

export default Loading;
