import React, { ReactNode, useEffect, useMemo } from 'react';
import AsyncBoundary from '../errorBoundary/AsyncBoundary';
import ErrorFallback from '../errorBoundary/ErrorFallback';
import { checkSignedIn } from '../../../shared/utils/cookieUtils';
import { useSetRecoilState } from 'recoil';
// import { ToastContainer } from 'react-toastify';
import { isSignedInState } from '../../../domains/auth/states/isSignedInState';
import useInitWithScrollTop from '../../hooks/useInitWithScrollTop';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import pages from '../../constants/pages';
import ResponsiveLayoutWithSideBar from '../Layout/ResponsiveLayoutWithSideBar';

interface PageLayoutProps {
    children?: ReactNode;
}

const useSetSignedIn = () => {
    const setIsSignedIn = useSetRecoilState(isSignedInState);
    useEffect(() => {
        setIsSignedIn(checkSignedIn() ? 'SIGNED_IN' : 'SIGNED_OUT');
    }, [setIsSignedIn]);
};
function PageLayout({ children }: PageLayoutProps) {
    useInitWithScrollTop();
    useSetSignedIn();

    const currentPath = useRouter().asPath;
    const currentPage = useMemo(() => {
        return Object.entries(pages).find(([_, pageInfo]) =>
            pageInfo.url === '/' ? false : currentPath.startsWith(pageInfo.url),
        )?.[1];
    }, [currentPath]);

    return (
        <>
            <AsyncBoundary
                errorFallback={({ error, resetBoundary }) => (
                    <ErrorFallback
                        error={error}
                        resetBoundary={resetBoundary}
                    />
                )}
            >
                {currentPage === undefined || currentPage.sidebar === 'NONE' ? (
                    <>{children}</>
                ) : (
                    <ResponsiveLayoutWithSideBar current={currentPage.sidebar}>
                        {children}
                    </ResponsiveLayoutWithSideBar>
                )}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="light"
                    style={{ zIndex: 99999 }}
                />
            </AsyncBoundary>
        </>
    );
}

export default PageLayout;
