import classNames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
/**
 * Skeleton: src가 remote image일 경우, 로드 중 일 때 잠시 나타나는 스케렐톤 이미지
 */
interface Props {
    containerClassName?: string;
    className?: string;
    path: string;
    alt: string;
    layout: 'fill' | 'intrinsic' | 'responsive';
    onClick?: React.MouseEventHandler;
    containerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    Skeleton?: React.ComponentType;
    onLoad?: () => void;
    onError?: () => void;
    width?: number;
    height?: number;
}
function S3StaticImage({
    containerClassName,
    className,
    path,
    alt,
    layout,
    onClick,
    containerStyle,
    style,
    Skeleton,
    onLoad,
    onError,
    width,
    height,
}: Props) {
    const [mySrc, setSrc] = React.useState(
        `${process.env.NEXT_PUBLIC_REMOTE_IMAGE_BASE_URL}/static/image${path}`,
    );
    const [dimensions, setDimensions] = useState<{
        width: number;
        height: number;
    } | null>(null);

    useEffect(() => {
        if (width && height) {
            setDimensions({
                width: width,
                height: height,
            });
        } else {
            const img = document.createElement('img') as HTMLImageElement;
            img.src = `${process.env.NEXT_PUBLIC_REMOTE_IMAGE_BASE_URL}/static/image${path}`;
            img.onload = () => {
                setDimensions({
                    width: img.width,
                    height: img.height,
                });
            };
        }
    }, [path, width, height]);

    return (
        <div
            className={classNames(styles.imgContainer, containerClassName)}
            onClick={onClick}
            style={{ width: `${dimensions?.width ?? 0}px`, ...containerStyle }}
        >
            {Skeleton ? (
                path && dimensions ? (
                    <Image
                        className={classNames(styles.img, className)}
                        alt={alt}
                        layout={layout}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        src={mySrc!}
                        style={style}
                        placeholder="blur"
                        blurDataURL={
                            'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg=='
                        }
                        onError={() => {
                            setSrc(
                                `${process.env.NEXT_PUBLIC_REMOTE_IMAGE_BASE_URL}/static/image/svg/image-error.svg`,
                            );
                        }}
                        onLoad={() => {
                            if (onLoad) onLoad();
                        }}
                        width={dimensions.width}
                        height={dimensions.height}
                        loader={({ src }) => src}
                        loading="lazy"
                    />
                ) : (
                    <Skeleton />
                )
            ) : (
                <Image
                    className={classNames(styles.img, className)}
                    alt={alt}
                    layout={layout}
                    src={mySrc ?? ''}
                    style={style}
                    onError={() => {
                        setSrc(
                            `${process.env.NEXT_PUBLIC_REMOTE_IMAGE_BASE_URL}/static/image/svg/image-error.svg`,
                        );
                        if (onError) onError();
                    }}
                    width={dimensions?.width ?? 0}
                    height={dimensions?.height ?? 0}
                    loader={({ src }) => src}
                    loading="lazy"
                />
            )}
        </div>
    );
}
S3StaticImage.defaultProps = {
    layout: 'responsive',
};
export default React.memo(S3StaticImage);
