import React, { ReactNode, useCallback, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/errorPage.module.scss';
// import ErrorImg from '../../../../public/assets/images/svg/error.svg';
import { ErrorType } from '../../types/common';
import { PageNameType } from '../../constants/pages';
import ErrorMessage from '../../constants/ErrorMessage';
import { ApiError, isInstanceOfAPIError } from '../../constants/error';
import NotFound from '../NotFound';
import { makeErrorStatement } from '../../utils/asyncUtils';
import useInitWithScrollTop from '../../hooks/useInitWithScrollTop';
import useAuthorizationHandler from '../../hooks/useAuthorizationHandler';
import useCustomHistory from '../../hooks/useCustomHistory';
import MyButton from '../Button/MyButton';
import ArrowImg from '../../../../public/assets/images/svg/arrow_right_filled.svg';
import S3StaticImage from '../CustomImage/S3StaticImage';
export interface ErrorFallbackProps {
    error: ErrorType;
    resetBoundary(): void;
}

const ErrorFallback = ({ error, resetBoundary }: ErrorFallbackProps) => {
    useInitWithScrollTop();

    const { handleLogoutTasks } = useAuthorizationHandler();
    useEffect(() => {
        /* 에러 페이지 렌더링 후 401 이면 로그아웃 진행 */
        if (axios.isAxiosError(error) && error?.response?.status === 401) {
            handleLogoutTasks();
        } else if (error && 'statusCode' in error) {
            const myError = error as ApiError;
            if (myError.statusCode === 401) {
                handleLogoutTasks();
            }
        }
        return resetBoundary;
    }, [error, handleLogoutTasks, resetBoundary]);

    const { go } = useCustomHistory();
    const movePageWithReset = useCallback(
        (pageName: PageNameType) => {
            resetBoundary();
            go(pageName);
        },
        [go, resetBoundary],
    );

    const buttonContainer = (
        <>
            <MyButton className={styles.backButton} onClick={resetBoundary}>
                Retry
            </MyButton>
            <MyButton onClick={() => movePageWithReset('mainPage')}>
                Home
                <ArrowImg fill={'#fff'} />
            </MyButton>
        </>
    );

    let renderedPage: ReactNode = (
        <>
            <div className={styles.paragraph}>
                <p>{ErrorMessage.INTERNAL_SERVER_ERROR_MESSAGE}</p>
            </div>
            {buttonContainer}
        </>
    );

    if (isInstanceOfAPIError(error)) {
        const { statusCode, message } = error;
        console.log('isInstanceOfAPIError ', error);
        if (statusCode === 401) {
            renderedPage = (
                <>
                    <div className={styles.paragraph}>
                        <p>{'Your login session has expired.'}</p>
                        <small>{'Please log in again.'}</small>
                    </div>

                    <MyButton
                        className={styles.backButton}
                        onClick={() => {
                            movePageWithReset('signInPage');
                        }}
                    >
                        Go to login page
                    </MyButton>
                </>
            );
        } else if (statusCode === 403 || statusCode === 500) {
            /* 403, 500은 정해진 문구로 처리*/
            const errorMessage = makeErrorStatement(statusCode).split('.');
            renderedPage = (
                <>
                    <S3StaticImage path={'/svg/error.svg'} alt={'error'} />
                    {/* <ErrorImg alt={'error'} /> */}
                    <div className={styles.paragraph}>
                        <p>{`${errorMessage[0]}.`}</p>
                        {errorMessage[1] && (
                            <small>{`${errorMessage[1]}.`}</small>
                        )}
                    </div>
                    {buttonContainer}
                </>
            );
        } else if (statusCode === 404) {
            renderedPage = <NotFound />;
        } else if (message) {
            /* 400이고 message가 존재한다면 error message 출력*/
            renderedPage = (
                <>
                    <S3StaticImage
                        containerClassName={styles.img}
                        path={'/svg/error.svg'}
                        alt={'error'}
                    />
                    {/* <ErrorImg className={styles.img} alt={'error'} /> */}
                    <div className={styles.paragraph}>
                        <p>{message}</p>
                    </div>
                    {buttonContainer}
                </>
            );
        }
    }
    return (
        <>
            <div className={styles.container}>
                <>{renderedPage}</>
            </div>
        </>
    );
};

export default ErrorFallback;
