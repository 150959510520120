import React from 'react';

interface LayoutProps extends React.HTMLAttributes<HTMLElement> {
    // style?: CSSProperties;
    // children: React.ReactNode;
    // className?: string;
}

const Layout = ({ children, ...props }: LayoutProps) => {
    return <div {...props}>{children}</div>;
};

export default Layout;
