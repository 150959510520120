import { googleLogout } from '@react-oauth/google';
import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { axiosInstance } from '../../api/axiosConfig';
import { isSignedInState } from '../../domains/auth/states/isSignedInState';
import { memberInfoState } from '../../domains/auth/states/memberInfoState';
import {
    makeAccessTokenValue,
    removeQueryCacheWithMember,
} from '../../shared/utils/authUtils';
import {
    removeSignedInCookie,
    removeUserTypeCookie,
    setSignedInCookie,
} from '../../shared/utils/cookieUtils';
import { logAmplitude, resetAmplitude } from '../utils/amplitudeUtils';

function useAuthorizationHandler() {
    const queryClient = useQueryClient();
    const setIsSignedIn = useSetRecoilState(isSignedInState);
    const handleLogInTasks = useCallback(
        (accessToken: string) => {
            /* 탈퇴되지 않은 회원의 경우: 로그인 처리 진행*/
            axiosInstance.defaults.headers.common['Authorization'] =
                makeAccessTokenValue(accessToken);
            setSignedInCookie();
            removeQueryCacheWithMember(queryClient);
            setIsSignedIn('SIGNED_IN');
            logAmplitude('Log In');
        },
        [queryClient, setIsSignedIn],
    );

    const resetMemberInfo = useResetRecoilState(memberInfoState);
    const handleLogoutTasks = useCallback(() => {
        axiosInstance.defaults.headers.common['Authorization'] = null;
        removeSignedInCookie();
        removeUserTypeCookie();
        setIsSignedIn('SIGNED_OUT');
        resetMemberInfo();
        removeQueryCacheWithMember(queryClient);
        resetAmplitude();
        logAmplitude('Log Out');
        googleLogout();
    }, [queryClient, resetMemberInfo, setIsSignedIn]);

    return { handleLogInTasks, handleLogoutTasks };
}

export default useAuthorizationHandler;
