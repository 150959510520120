import { atom } from 'recoil';
import { UserInfo } from '../../../shared/types/users';
import { atomKey } from '../../../shared/utils/recoilUtils';

const initState: UserInfo = {
    idx: 0,
    email: '',
    roles: [''],
    status: 'ACTIVE',
    iconFullPath: '',
    userType: null,
    socialPlatformType: undefined,
};
export const memberInfoState = atom<UserInfo>({
    key: atomKey('memberInfoState'),
    default: initState,
});
