import {
    CompanyEmployee,
    CompanyEmployeeLabel,
    CompanyRevenue,
    CompanyRevenueLabel,
    FeedbackType,
    FundingStage,
    FundingStageLabel,
    InvestmentLocation,
    InvestmentLocationLabel,
    InvestorType,
    InvestorTypeLabel,
} from '../../domains/my-page/types';
import { LabeledValue } from '../types/common';

export const TIMER_INIT_SECONDS = 180;

export const FUNDING_STAGE_LABEL: LabeledValue<
    FundingStage,
    FundingStageLabel
>[] = [
    { value: 'PRE_SEED', label: 'Pre-seed' },
    { value: 'SEED', label: 'Seed' },
    { value: 'PRE_A', label: 'Pre A' },
    { value: 'SERIES_A', label: 'Series A' },
    { value: 'SERIES_B', label: 'Series B' },
    { value: 'SERIES_C', label: 'Series C' },
    { value: 'SERIES_D', label: 'Series D' },
    { value: 'PRE_IPO', label: 'Pre-IPO' },
];

export const INVESTOR_TYPE_LABEL: LabeledValue<
    InvestorType,
    InvestorTypeLabel
>[] = [
    { value: 'AC_INVESTOR', label: 'AC' },
    { value: 'VC_INVESTOR', label: 'VC' },
    { value: 'ANGEL_INVESTOR', label: 'ANGEL' },
    { value: 'PE_INVESTOR', label: 'PE' },
    { value: 'CVC_INVESTOR', label: 'CVC' },
];
export const INVESTMENT_LOCATION_LABEL: LabeledValue<
    InvestmentLocation,
    InvestmentLocationLabel
>[] = [
    { value: 'AFRICA', label: 'Africa' },
    { value: 'NORTH_AMERICA', label: 'America/North' },
    { value: 'SOUTH_AMERICA', label: 'America/South' },
    { value: 'CENTRAL_ASIA', label: 'Asia/Central' },
    { value: 'EAST_ASIA', label: 'Asia/East' },
    { value: 'SOUTH_ASIA', label: 'Asia/South' },
    { value: 'SOUTHEAST_ASIA', label: 'Asia/Southeast' },
    { value: 'EUROPE', label: 'Europe' },
    { value: 'MIDDLE_EAST', label: 'Middle East' },
    { value: 'OCEANIA', label: 'Oceania' },
];

export const COMPANY_REVENUE_LABEL: LabeledValue<
    CompanyRevenue,
    CompanyRevenueLabel
>[] = [
    { value: 'LESS_THAN_100K', label: 'Less than $100,000' },
    { value: 'BETWEEN_100K_AND_1M', label: '$100,000 - $1 million' },
    { value: 'BETWEEN_1M_AND_5M', label: '$1 million - $5 million' },
    { value: 'BETWEEN_5M_AND_10M', label: '$5 million - $10 million' },
    { value: 'BETWEEN_10M_AND_50M', label: '$10 million - $50 million' },
    { value: 'BETWEEN_50M_AND_100M', label: '$50 million - $100 million' },
    { value: 'OVER_100M', label: 'Over $100 million' },
];

export const COMPANY_EMPLOYEE_LABEL: LabeledValue<
    CompanyEmployee,
    CompanyEmployeeLabel
>[] = [
    { value: 'FEWER_THAN_10', label: 'Fewer than 10 employees' },
    { value: 'BETWEEN_10_AND_50', label: '10 - 50 employees' },
    { value: 'BETWEEN_50_AND_100', label: '50 - 100 employees' },
    { value: 'BETWEEN_100_AND_200', label: '100 - 200 employees' },
    { value: 'BETWEEN_200_AND_500', label: '200 - 500 employees' },
    { value: 'BETWEEN_500_AND_1000', label: '500 - 1,000 employees' },
    { value: 'OVER_1000', label: 'Over 1,000 employees' },
];
export const INVESTMENT_STAGE_OBJ: Record<FundingStage, FundingStageLabel> = {
    PRE_SEED: 'Pre-seed',
    SEED: 'Seed',
    PRE_A: 'Pre A',
    SERIES_A: 'Series A',
    SERIES_B: 'Series B',
    SERIES_C: 'Series C',
    SERIES_D: 'Series C',
    PRE_IPO: 'Pre-IPO',
};

export const INVESTMENT_STAGE_NUM_VALUE: Record<FundingStage, number> = {
    PRE_SEED: 0,
    PRE_A: 1,
    SEED: 2,
    SERIES_A: 3,
    SERIES_B: 4,
    SERIES_C: 5,
    SERIES_D: 6,
    PRE_IPO: 7,
};
export const BIOGRAPHY_MAX_LENGTH = 2000;

export const DOCUMENT_SUMMARY_MAX_LENGTH = 2000;
export const TOTAL_LIVE_FEEDBACK_NUM = 3;
export const MESSAGE_MAX_LENGTH = 150;
export const LIVE_FEEDBACK_MESSAGE_MAX_LENGTH = 150;

export const STAR_POINT_FEEDBACK_MIN_LENGTH = 10;
export const STAR_POINT_FEEDBACK_MAX_LENGTH = 100;
export const MENTOR_BIOGRAPHY_MIN_LENGTH = 500;
export const MENTOR_BIOGRAPHY_MAX_LENGTH = 2000;

export const CANCEL_REASON_MIN_LENGTH = 10;
export const CANCEL_REASON_MAX_LENGTH = 50;

export const MB = 1024 * 1024;

export const INVESTEE_BUSINESS_DOMAIN_NUMS = 3;
export const INVESTOR_BUSINESS_DOMAIN_NUMS = 6;

export const MAX_FILE_SIZE = 30;
export const MAX_PAGE_LIMIT = 50;

export const LIVE_FEEDBACK_IMAGE_NUM = 2;

export const INVESTMENT_DESCRIPTION = 500;

export const FEEDBACK_TYPE_LABEL: LabeledValue<FeedbackType, string>[] = [
    { value: 'VIDEO', label: 'Video Call' },
    { value: 'RECORDING', label: 'Video Review' },
    { value: 'DOCUMENT', label: 'Document Review' },
];
