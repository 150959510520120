import { QueryClient } from 'react-query';

export default new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
            useErrorBoundary: true,
            keepPreviousData: true,
            staleTime: 1000 * 60 * 10, // 10 mins
            cacheTime: 1000 * 60 * 10, // 10 mins
            retry: 1,
        },
        mutations: {
            useErrorBoundary: true,
        },
    },
});
