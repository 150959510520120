import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useMyStartUpManagerProfileQuery from '../../domains/my-page/api/investee/useMyStartUpManagerProfileQuery';
import useInvesteeInfoState from '../hooks/states/investee/useInvesteeInfoState';
import { setUserTypeCookie } from '../../shared/utils/cookieUtils';
import {
    isInvesteeUser,
    isInvestorUser,
    isMentorUser,
} from '../../shared/utils/memberUtil';
import useCheckSignedIn from '../hooks/useCheckSignedIn';
import { memberInfoState } from '../../domains/auth/states/memberInfoState';
import useMyInvestorProfileQuery from '../../domains/my-page/api/investor/useMyInvestorProfileQuery';
import useInvestorInfoState from '../hooks/states/investor/useInvestorInfoState';
import useMyMentorProfileQuery from '../../domains/my-page/api/investor/mentor/useMyMentorProfileQuery';
import useMentorInfoState from '../hooks/states/mentor/useMentorInfoState';
import { setAmplitudeUserId } from '../utils/amplitudeUtils';
import { useFetchMemberInfo } from '../../domains/auth/hooks/api/signIn';

function MemberInfo() {
    const isSignedIn = useCheckSignedIn();

    const { data: fetchMemberInfoResponse } = useFetchMemberInfo({
        enabled: isSignedIn,
        suspense: false,
    });

    const setMemberInfo = useSetRecoilState(memberInfoState);
    useEffect(() => {
        if (fetchMemberInfoResponse?.content) {
            setMemberInfo(fetchMemberInfoResponse.content);
            if (fetchMemberInfoResponse.content.idx)
                setAmplitudeUserId(fetchMemberInfoResponse.content.idx);
            if (fetchMemberInfoResponse.content.userType)
                setUserTypeCookie(fetchMemberInfoResponse.content.userType);
        }
    }, [fetchMemberInfoResponse, setMemberInfo]);

    const { userType } = useRecoilValue(memberInfoState);

    const { data: fetchMentorProfileResponse } = useMyMentorProfileQuery({
        enabled: isSignedIn && isMentorUser(userType),
        suspense: false,
    });
    const { setMentorInfo } = useMentorInfoState();
    useEffect(() => {
        if (fetchMentorProfileResponse?.content)
            setMentorInfo(fetchMentorProfileResponse?.content);
    }, [fetchMentorProfileResponse?.content, setMentorInfo]);

    const { data: fetchInvestorProfileResponse } = useMyInvestorProfileQuery({
        enabled: isSignedIn && isInvestorUser(userType),
        suspense: false,
    });
    const { setInvestorInfo } = useInvestorInfoState();
    useEffect(() => {
        if (fetchInvestorProfileResponse?.content)
            setInvestorInfo(fetchInvestorProfileResponse.content);
    }, [fetchInvestorProfileResponse?.content, setInvestorInfo]);

    const { setInvesteeInfo } = useInvesteeInfoState();
    const { data: fetchStartUpManagerProfileResponse } =
        useMyStartUpManagerProfileQuery({
            enabled: isSignedIn && isInvesteeUser(userType),
            suspense: false,
        });
    useEffect(() => {
        if (fetchStartUpManagerProfileResponse?.content)
            setInvesteeInfo(fetchStartUpManagerProfileResponse.content);
    }, [fetchStartUpManagerProfileResponse?.content, setInvesteeInfo]);

    return <></>;
}

export default MemberInfo;
