import React from 'react';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';
import queryClient from '../../../../config/queryClient';
import { InvestorInfo } from '../../../../domains/my-page/types';
import queryKeyWithMember from '../../../constants/queryKeyWithMember';
import { investorInfoState } from '../../../states/investor/investorInfoState';

const useInvestorInfoState = () => {
    const [investorInfo, setInvestorInfo] = useRecoilState(investorInfoState);
    const reset = useResetRecoilState(investorInfoState);
    const updateInvestorInfo = useRecoilCallback(
        ({ set }) =>
            (updatedInvestorInfo: Partial<InvestorInfo>) => {
                queryClient.removeQueries(queryKeyWithMember.myProfile.key);
                const updated = Object.entries(updatedInvestorInfo)
                    .filter(([_, value]) => value !== undefined)
                    .reduce((prev, [key, value]) => {
                        prev[key] = value;
                        return prev;
                    }, {});
                set(investorInfoState, (prev) => ({ ...prev, ...updated }));
            },
    );
    return {
        investorInfo,
        setInvestorInfo,
        updateInvestorInfo,
        reset,
    };
};

export default useInvestorInfoState;
