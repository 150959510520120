import { QueryFunctionContext } from 'react-query';
import {
    FeedbackType,
    FundingStage,
    InvestmentLocation,
    InvestorInfo,
    InvestorType,
    InvestorUpdateRequest,
    UpdateProfileRequest,
} from '../../domains/my-page/types';
import { CommonResult, SingleResult } from '../types/NetworkResult';

import { axiosInstance } from '../axiosConfig';
import {
    MentorForListingResponse,
    SignUpInvestorWithEmailRequest,
    SignUpInvestorWithOAuthRequest,
} from '../types/investor';

export async function fetchMentorList({
    queryKey,
}: QueryFunctionContext): Promise<MentorForListingResponse> {
    const [
        ,
        feedbackTypes,
        page,
        stages,
        businessDomains,
        investorTypes,
        investmentLocations,
        voucherEventIdxes,
    ] = queryKey;
    const reducer = (accumulator, currentValue, currentIndex) => {
        return currentIndex ? accumulator + ',' + currentValue : currentValue;
    };
    const stagesString: string = (stages as FundingStage[]).reduce(reducer, '');
    const businessCategoryIdxes: string = (businessDomains as number[]).reduce(
        (accumulator, currentValue, currentIndex) => {
            return currentIndex
                ? accumulator + ',' + currentValue.toString()
                : currentValue.toString();
        },
        '',
    );
    const investmentLocationsString: string = (
        investmentLocations as InvestmentLocation[]
    ).reduce(reducer, '');
    const feedbackTypesString: string = (
        feedbackTypes as FeedbackType[]
    ).reduce(reducer, '');

    const investorTypesString: string = (
        investorTypes as InvestorType[]
    ).reduce(reducer, '');

    const voucherEventIdxesString: string = (
        voucherEventIdxes as number[]
    ).reduce(reducer, '');

    const { data } = await axiosInstance.get('/mentors', {
        params: {
            page,
            size: 10,
            stages: stagesString,
            businessCategoryIdxes: businessCategoryIdxes,
            feedbackTypes: feedbackTypesString,
            investorTypes: investorTypesString,
            investmentLocations: investmentLocationsString,
            voucherEventIdxes: voucherEventIdxesString,
        },
    });
    return data;
}

export async function fetchMentorListByName({
    queryKey,
}: QueryFunctionContext): Promise<MentorForListingResponse> {
    const [
        ,
        feedbackTypes,
        page,
        stages,
        businessDomains,
        investorTypes,
        investmentLocations,
        voucherEventIdxes,
        name,
    ] = queryKey;
    const reducer = (accumulator, currentValue, currentIndex) => {
        return currentIndex ? accumulator + ',' + currentValue : currentValue;
    };
    const stagesString: string = (stages as FundingStage[]).reduce(reducer, '');
    const businessCategoryIdxes: string = (businessDomains as number[])
        .reduce((accumulator, currentValue, currentIndex) => {
            return currentIndex
                ? accumulator + ',' + currentValue.toString()
                : currentValue.toString();
        }, '')
        .toString();
    const feedbackTypesString: string = (
        feedbackTypes as FeedbackType[]
    ).reduce(reducer, '');

    const investorTypesString: string = (
        investorTypes as InvestorType[]
    ).reduce(reducer, '');
    const investmentLocationsString: string = (
        investmentLocations as InvestmentLocation[]
    ).reduce(reducer, '');
    const voucherEventIdxesString: string = (
        voucherEventIdxes as number[]
    ).reduce(reducer, '');

    const { data } = await axiosInstance.request({
        url: '/mentors/name',
        method: 'get',
        params: {
            page,
            size: 10,
            stages: stagesString,
            businessCategoryIdxes: businessCategoryIdxes,
            feedbackTypes: feedbackTypesString,
            investorTypes: investorTypesString,
            investmentLocations: investmentLocationsString,
            voucherEventIdxes: voucherEventIdxesString,
            name: name,
        },
    });
    return data;
}

export async function certifyBusinessCard(
    investorIdx: number,
): Promise<CommonResult> {
    const response = await axiosInstance.get(
        '/investors/' + investorIdx + '/bcard/request',
    );
    return response.data;
}

export async function fetchMyInvestorProfile(): Promise<
    SingleResult<InvestorInfo>
> {
    const response = await axiosInstance.get('/investors/my');
    return response.data;
}

export async function updateInvestorProfile(
    request: UpdateProfileRequest<InvestorUpdateRequest>,
): Promise<CommonResult> {
    // await new Promise((r) => setTimeout(r, 3000));
    const response = await axiosInstance.patch(
        `/investors/${request.idx}`,
        request.data,
    );
    return response.data;
}

export async function signUpInvestorWithEmail(
    signUpRequest: SignUpInvestorWithEmailRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.post('/investors', signUpRequest);
    return response.data;
}

export async function signUpInvestorWithOAuth(
    signUpRequest: SignUpInvestorWithOAuthRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.post(
        '/investors/oauth',
        signUpRequest,
    );
    return response.data;
}
