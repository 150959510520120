import React from 'react';
import styles from './styles/errorPage.module.scss';
// import Img404 from '../../../public/assets/images/svg/404.svg';
import ArrowImg from '../../../public/assets/images/svg/arrow_right_filled.svg';
import MyLink from './Link/MyLink';
import MyButton from './Button/MyButton';
import S3StaticImage from './CustomImage/S3StaticImage';
const NotFound = () => {
    // const { go } = useMyHistory();
    return (
        <div className={styles.container}>
            {/* <Img404 alt={'NOT FOUND'} />{' '} */}
            <S3StaticImage path={'/svg/404.svg'} alt={'NOT FOUND'} />
            <div className={styles.paragraph}>
                <p>Page not Found</p>
                <small>
                    The page you are looking for doesn’t exist or has been moved
                </small>
            </div>
            <MyLink pageName={'mainPage'} passHref legacyBehavior>
                <MyButton variant="secondary" className={styles.backButton}>
                    <a>Home</a>
                    <ArrowImg fill={'#fff'} />
                </MyButton>
            </MyLink>
        </div>
    );
};

export default React.memo(NotFound);
