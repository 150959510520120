/* member와 관련있는 키.*/

import { QueryKey } from 'react-query';

const KEY_LIST = [
    'agreement',
    'notificationAgreement',
    'myMemberInfo',
    'myProfile',
    'mentorInfo',
    'giftCardHistory',
    'pointTransactionHistory',
    'paymentHistory',
    'userPoint',
    'settlementHistory',
    'settlementSummary',
    'userTimezone',
    'investeeFeedbackMatchingHistory',
    'investeeFeedbackInfo',
    'investeeFeedbackMatchingInfo',
    'investeeMyIrInfo',
    'specificOrderInfo',
    'feedbackMatchingsByMentor',
    'investeeProfileByMentor',
    'mentorFeedbackMatchingInfo',
    'irTempFeedbackInfo',
    'liveTempFeedbackInfo',
    'investeeIrFeedbackInfo',
    'investeeLiveFeedbackInfo',
    'mentorDocumentFeedbackInfo',
    'investorLiveFeedbackInfo',
    'investorToMentorRequestInfo',
    'mentorVideoFeedbackAvailableTimeInfo',
    'mentorCalendarSyncInfo',
    'mentorUserReferralCode',
    'mentorOauthCalendarEventList',
    'mentorTotalEventList',
    'requestedLiveFeedbackMatchingMentoringInfoList',
    'investeeCurrentAvailableVoucherInfo',
    'investeeCurrentAvailableVoucherInfoForMentor',
    'investeeAllVoucherInfo',
    'allVoucherEventList',
    'linkedInToken',
    'orderInfo',
    'refundPoint',
    'refundPG',
    'refundVoucher',
    'transcript',
] as const;

type KeyType = typeof KEY_LIST[number];

interface QueryKeyObj {
    key: QueryKey;
    superKey: (
        ...args: (string | number | boolean | string[] | number[])[]
    ) => QueryKey;
}

const queryKeyObject = (key: KeyType): QueryKeyObj =>
    ({
        key,
        superKey: (
            ...args: (string | number | boolean | string[] | number[])[]
        ) => [key, ...args],
    } as QueryKeyObj);

const queryKeyWithMember: Record<KeyType, QueryKeyObj> = {
    agreement: queryKeyObject('agreement'),
    notificationAgreement: queryKeyObject('notificationAgreement'),
    myMemberInfo: queryKeyObject('myMemberInfo'),
    myProfile: queryKeyObject('myProfile'),
    mentorInfo: queryKeyObject('mentorInfo'),
    giftCardHistory: queryKeyObject('giftCardHistory'),
    pointTransactionHistory: queryKeyObject('pointTransactionHistory'),
    paymentHistory: queryKeyObject('paymentHistory'),
    userPoint: queryKeyObject('userPoint'),
    settlementHistory: queryKeyObject('settlementHistory'),
    userTimezone: queryKeyObject('userTimezone'),
    settlementSummary: queryKeyObject('settlementSummary'),
    investeeFeedbackMatchingHistory: queryKeyObject(
        'investeeFeedbackMatchingHistory',
    ),
    investeeFeedbackInfo: queryKeyObject('investeeFeedbackInfo'),
    investeeFeedbackMatchingInfo: queryKeyObject(
        'investeeFeedbackMatchingInfo',
    ),
    investeeMyIrInfo: queryKeyObject('investeeMyIrInfo'),
    specificOrderInfo: queryKeyObject('specificOrderInfo'),
    feedbackMatchingsByMentor: queryKeyObject('feedbackMatchingsByMentor'),
    investeeProfileByMentor: queryKeyObject('investeeProfileByMentor'),
    mentorFeedbackMatchingInfo: queryKeyObject('mentorFeedbackMatchingInfo'),
    irTempFeedbackInfo: queryKeyObject('irTempFeedbackInfo'),
    liveTempFeedbackInfo: queryKeyObject('liveTempFeedbackInfo'),
    investeeIrFeedbackInfo: queryKeyObject('investeeIrFeedbackInfo'),
    investeeLiveFeedbackInfo: queryKeyObject('investeeLiveFeedbackInfo'),
    mentorDocumentFeedbackInfo: queryKeyObject('mentorDocumentFeedbackInfo'),
    investorLiveFeedbackInfo: queryKeyObject('investorLiveFeedbackInfo'),
    investorToMentorRequestInfo: queryKeyObject('investorToMentorRequestInfo'),
    mentorVideoFeedbackAvailableTimeInfo: queryKeyObject(
        'mentorVideoFeedbackAvailableTimeInfo',
    ),
    mentorCalendarSyncInfo: queryKeyObject('mentorCalendarSyncInfo'),
    mentorUserReferralCode: queryKeyObject('mentorUserReferralCode'),
    mentorOauthCalendarEventList: queryKeyObject(
        'mentorOauthCalendarEventList',
    ),
    mentorTotalEventList: queryKeyObject('mentorTotalEventList'),
    requestedLiveFeedbackMatchingMentoringInfoList: queryKeyObject(
        'requestedLiveFeedbackMatchingMentoringInfoList',
    ),
    investeeCurrentAvailableVoucherInfo: queryKeyObject(
        'investeeCurrentAvailableVoucherInfo',
    ),
    investeeCurrentAvailableVoucherInfoForMentor: queryKeyObject(
        'investeeCurrentAvailableVoucherInfoForMentor',
    ),
    investeeAllVoucherInfo: queryKeyObject('investeeAllVoucherInfo'),
    allVoucherEventList: queryKeyObject('allVoucherEventList'),
    linkedInToken: queryKeyObject('linkedInToken'),
    orderInfo: queryKeyObject('orderInfo'),
    refundPoint: queryKeyObject('refundPoint'),
    refundPG: queryKeyObject('refundPG'),
    refundVoucher: queryKeyObject('refundVoucher'),
    transcript: queryKeyObject('transcript'),
};

export default queryKeyWithMember;
