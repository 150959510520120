import { atom } from 'recoil';
import { atomKey } from '../../utils/recoilUtils';
import { InvesteeInfo } from '../../../domains/my-page/types';

const initState: InvesteeInfo = {
    idx: 0,
    companyInfo: {
        companyName: '',
        homeUrl: '',
        introduction: '',
        companyEmployeeSize: undefined,
        companyRevenueSize: undefined,
        companyCountry: undefined,
    },
    businessCategories: [],
    stages: [],
    snsList: [],
    email: '',
    name: '',
    // matchingCount: 0,
    // matchingRequestCount: 0,
    // succeedMatchingCount: 0,
    // lastMatchingDate: undefined,
    biography: '',
    iconFullPath: '',
};

export const investeeInfoState = atom<InvesteeInfo>({
    key: atomKey('investeeInfoState'),
    default: initState,
});
