// 인코딩
export const encodeString = (str: string): string => {
    const utf8Array = new TextEncoder().encode(str);
    return utf8Array.join('-');
};

// 디코딩
export const decodeString = (encodedStr: string): string => {
    const utf8Array = Uint8Array.from(encodedStr.split('-').map(Number));
    return new TextDecoder().decode(utf8Array);
};
