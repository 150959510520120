import React, { ChangeEvent } from 'react';
import pages from '../constants/pages';
import * as R from 'ramda';
import { MB } from '../constants/common';
import { IMaybe } from '../types/common';

export function unixTimestampToLocalDate(unixTimestamp: number) {
    const date = new Date(unixTimestamp * 1000);
    return (
        date.toLocaleDateString('ko-KR') +
        ' ' +
        date.toLocaleTimeString('ko-KR')
    );
}
export function getCurrentTimeUTC() {
    const curr = new Date();
    // 2. UTC 시간 계산
    const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;
    return new Date(utc);
}
// export function getCurrentTimeKST() {
//     const curr = new Date();
//     // 2. UTC 시간 계산
//     const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;
//     // 3. UTC to KST (UTC + 9시간)
//     const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
//     const kr_curr = new Date(utc + KR_TIME_DIFF);
//     return kr_curr;
// }

export function getTimeInDaysHoursMinsSecs(distance: number) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return [days, hours, minutes, seconds];
}

export function valueToEventObject(
    value: string | number,
): ChangeEvent<HTMLInputElement> {
    return { target: { value: value } } as ChangeEvent<HTMLInputElement>;
}

/*
// input format : YYYY
// output format : aa (a=age)
export function ageCalculator(birthYear) {
    let today = new Date();

    let year = today.getFullYear();

    let age = year - birthYear + 1;

    return age;
}
*/

// input format : YYYY-MM-DD HH-MM-SS
// output format : YYYY.MM.DD
export function yearMonthDayGenerator(dateTime?: string) {
    if (dateTime === undefined) return;
    const year = dateTime.substr(0, 4);
    const monthDay = dateTime.substr(5, 6);
    const month = monthDay.substr(0, 2);
    const day = monthDay.substr(3, 4);

    const result = year + '.' + month + '.' + day;
    return result;
}

export function makeTwoLengthNumber(number: number) {
    return (number < 10 ? '0' : '') + number;
}

export function numberingFormatForThousand(num: number) {
    if (num === undefined || num === null) return '';
    // return Math.floor(num)
    //     .toString()
    //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return Math.floor(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function makeUTCDateToKTCDate(date: Date) {
    const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
    const ktcDate = new Date(utc + KR_TIME_DIFF);

    return ktcDate.getTime();
}

export function changeFormatOfStringDate(date: string) {
    if (date) {
        const t = date.split(/[- :]/);
        let d = new Date();

        // Apply each element to the Date function
        if (t.length === 6)
            d = new Date(
                Number(t[0]),
                Number(t[1]) - 1,
                Number(t[2]),
                Number(t[3]),
                Number(t[4]),
                Number(t[5]),
            );
        else if (t.length === 3)
            d = new Date(Number(t[0]), Number(t[1]) - 1, Number(t[2]));
        const actionDate = new Date(d);

        return actionDate;
    } else {
        return new Date();
    }
}

export const openNewTab = (url: string) => {
    const win = window.open(url, '_blank');
    // if (win) win.focus();
    // else toast.success("팝업 차단 기능을 해제하세요.");
};

export const openPreviewPageWithNewTab = (irData?: Blob | null) =>
    irData
        ? openNewTab(
              `${pages.evaluationPreviewPage.url}?path=${URL.createObjectURL(
                  irData,
              )}`,
          )
        : openNewTab(`${pages.evaluationPreviewPage.url}`);

export const multipleLineTextToHtmlDivBlock = (text: string) => {
    const influencerNoticeArr = text.split('\n');
    const formattedNotice = influencerNoticeArr?.map((el, idx) => {
        return <div key={idx}>{el}</div>;
    });
    return formattedNotice;
};

export const calculateTimeDifference = (startDate: number, endDate: number) => {
    let diff = endDate - startDate;
    const dd = Math.floor(diff / 1000 / 60 / (60 * 24));
    diff -= dd * 1000 * 60 * (60 * 24);
    const hh = Math.floor(diff / 1000 / 60 / 60);
    diff -= hh * 1000 * 60 * 60;
    const mm = Math.floor(diff / 1000 / 60);
    diff -= mm * 1000 * 60;
    const ss = Math.floor(diff / 1000);
    diff -= ss * 1000;
    return {
        days: dd,
        hours: hh,
        minutes: mm,
        seconds: ss,
        milliseconds: diff,
    };
};

export const hideMiddlePhoneNumber = (phoneNumber: string) => {
    return (
        phoneNumber.slice(0, 3).replace(/-/gi, '.') +
        '.' +
        phoneNumber
            .slice(3, 7)
            .replace(/-/gi, '.')
            .replace(new RegExp('[0-9]', 'g'), 'X') +
        '.' +
        phoneNumber.slice(7, phoneNumber.length).replace(/-/gi, '.')
    );
};

export function parseJson(jsonString: string) {
    try {
        return JSON.parse(jsonString);
    } catch (error: any) {
        return {};
    }
}

export function removeWhiteSpace(str: string | undefined) {
    return str?.replace(/\n/g, '').replace(/\s*/g, '') ?? '';
}

export const getFileList = (e: React.ChangeEvent<HTMLInputElement>): File[] => {
    const files = e.target.files;
    const fileArray = Array.prototype.slice.call(files);
    const fileList: File[] = [];
    fileArray.forEach((file) => {
        fileList.push(file);
    });
    return fileList;
};

export const makeFormData = (
    name: string,
    value: string | Blob,
    fileName?: string | undefined,
): FormData => {
    const formData = new FormData();
    formData.append(name, value, fileName);
    return formData;
};

export const flattenObj = (obj: { [key: string]: any }) => {
    // The object which contains the
    // final result
    const result = {};
    // loop through the object "ob"
    for (const i in obj) {
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
            if (obj[i] === null) {
                result[i] = obj[i];
                continue;
            }
            const temp = flattenObj(obj[i]);
            for (const j in temp) {
                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        } else {
            result[i] = obj[i];
        }
    }
    return result;
};

export const fullImagePath = (imagePath: string) => {
    return imagePath.startsWith('static')
        ? `${process.env.NEXT_PUBLIC_FRONT_URL}/${imagePath}`
        : `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL}/${imagePath}`;
};
export const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

export const isNotNil = R.complement(R.isNil);

export const propEq =
    (prop: string, value: any) => (obj: { [key: string]: any }) =>
        R.equals(R.prop(prop, obj), value);

export const checkImageSize = (image: File) => {
    const MAX_IMAGE_SIZE = 5 * MB;
    return image.size < MAX_IMAGE_SIZE;
};

export const listHasUrl = (list: string[], url: string) =>
    list.some((path) => url.startsWith(path));

export const extractLinkedinId = (input: string): string | null => {
    const regex = /linkedin\.com\/in\/([^/]+)/;
    const match = input.match(regex);
    return match ? match[1] : null;
};

export const linkedInURL = (input: string): string | undefined => {
    if (input === '') return '';
    const regex = /linkedin\.com\/in\/([^/]+)/;
    const match = input.match(regex);
    if (match) return `linkedin.com/in/${extractLinkedinId(input)}`;
    else return undefined;
};
