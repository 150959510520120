import axios, { AxiosResponse } from 'axios';
import {
    NotFoundError,
    ForbiddenError,
    AuthError,
    InternalServerError,
} from '../shared/constants/error';
import { SignInResponse } from './types/auth';
import { makeAccessTokenValue } from '../shared/utils/authUtils';

type OnTokenRefreshedCallback = (accessToken: string) => void;
// axios.defaults.baseURL = process.env.NEXT_PUBLIC_FRONT_URL;
axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKEND_URL;

let isTokenRefreshing = false;
let refreshSubscribers: Array<OnTokenRefreshedCallback> = [];

const onTokenRefreshed = (accessToken: string) => {
    refreshSubscribers?.map((callback) => {
        callback(accessToken);
    });
    refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: OnTokenRefreshedCallback) => {
    refreshSubscribers.push(callback);
};

export const axiosInstance = axios.create({
    timeout: 120000,
});

axiosInstance.interceptors.request.use(
    function (config) {
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.withCredentials = true;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

// axiosInstance.interceptors.response.use(
//     async (response) => {
//         return response;
//     },
//     async (error) => {
//         const { config, response } = error;

//         if (response) {
//             const originalRequest = config;
//             const status = response.status;
//             if (status === 401) {
//                 if (!isTokenRefreshing) {
//                     // isTokenRefreshing이 false인 경우에만 token refresh 요청
//                     isTokenRefreshing = true;

//                     try {
//                         const response: AxiosResponse<SignInResponse> =
//                             await axios.post(
//                                 '/auth/refresh',
//                                 {},
//                                 { withCredentials: true },
//                             );
//                         // 401로 요청 실패했던 요청 새로운 accessToken으로 재요청
//                         if (response?.data?.content?.accessToken) {
//                             const newAccessToken =
//                                 response.data.content.accessToken;
//                             axiosInstance.defaults.headers.common[
//                                 'Authorization'
//                             ] = makeAccessTokenValue(newAccessToken);
//                             // setAccessTokenCookie(newAccessToken);
//                             // 기존에 쌓여있던 request 처리
//                             onTokenRefreshed(newAccessToken);
//                             originalRequest.headers.Authorization =
//                                 makeAccessTokenValue(newAccessToken);
//                             isTokenRefreshing = false;
//                             // 현재 request 처리
//                             return axiosInstance(originalRequest);
//                         } else {
//                             // 기존에 쌓여있던 request 초기화
//                             refreshSubscribers = [];
//                             isTokenRefreshing = false;
//                         }
//                     } catch {
//                         refreshSubscribers = [];
//                         isTokenRefreshing = false;
//                     }
//                 } else {
//                     // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
//                     const retryOriginalRequest = new Promise((resolve) => {
//                         addRefreshSubscriber((accessToken) => {
//                             originalRequest.headers.Authorization =
//                                 makeAccessTokenValue(accessToken);
//                             resolve(axios(originalRequest));
//                         });
//                     });
//                     return retryOriginalRequest;
//                 }
//                 throw new AuthError();
//             }
//             if (status === 500) {
//                 throw new InternalServerError();
//             }
//             if (status === 404) {
//                 throw new NotFoundError();
//             }

//             if (status === 403) {
//                 throw new ForbiddenError();
//             }

//             if (status === 400) {
//                 return response;
//             }
//         }

//         // api 결과가 error를 리턴
//         return Promise.reject(error);
//     },
// );

axiosInstance.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        const { config, response } = error;

        if (response) {
            const originalRequest = config;
            const status = response.status;
            if (status === 401) {
                if (!isTokenRefreshing) {
                    isTokenRefreshing = true;

                    try {
                        const response: AxiosResponse<SignInResponse> =
                            await axios.post(
                                '/auth/refresh',
                                {},
                                { withCredentials: true },
                            );
                        if (response?.data?.content?.accessToken) {
                            const newAccessToken =
                                response.data.content.accessToken;
                            axiosInstance.defaults.headers.common[
                                'Authorization'
                            ] = makeAccessTokenValue(newAccessToken);
                            onTokenRefreshed(newAccessToken);
                            originalRequest.headers.Authorization =
                                makeAccessTokenValue(newAccessToken);
                            isTokenRefreshing = false;
                            // 여기서 axiosInstance를 사용하여 요청을 다시 보냄
                            return axiosInstance(originalRequest);
                        } else {
                            refreshSubscribers = [];
                            isTokenRefreshing = false;
                        }
                    } catch {
                        refreshSubscribers = [];
                        isTokenRefreshing = false;
                    }
                } else {
                    const retryOriginalRequest = new Promise((resolve) => {
                        addRefreshSubscriber((accessToken) => {
                            originalRequest.headers.Authorization =
                                makeAccessTokenValue(accessToken);
                            resolve(axiosInstance(originalRequest)); // 여기서 axiosInstance를 사용
                        });
                    });
                    return retryOriginalRequest;
                }
                throw new AuthError();
            }
            if (status === 500) {
                throw new InternalServerError();
            }
            if (status === 404) {
                throw new NotFoundError();
            }
            if (status === 403) {
                throw new ForbiddenError();
            }
            if (status === 400) {
                return response;
            }
        }

        return Promise.reject(error);
    },
);
