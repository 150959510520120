import classNames from 'classnames';
import Link from 'next/link';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import pages, { PageNameType } from '../../constants/pages';
import styles from './index.module.scss';
import queryString from 'query-string';

interface Props {
    pageName: PageNameType;
    children: ReactNode;
    params?: string[];
    queryObj?: Record<string, string | number>;
    className?: string;
    style?: React.CSSProperties;
    passHref?: boolean;
    legacyBehavior?: boolean;
}
const MyLink = ({
    pageName,
    children,
    params,
    queryObj,
    className,
    style,
    passHref,
    legacyBehavior,
}: Props) => {
    const paramString = useMemo(() => {
        return (params ?? []).reduce(
            (previousValue, currentValue) => `${previousValue}/${currentValue}`,
            '',
        );
    }, [params]);

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <Link
            href={`${pages[pageName].url}${paramString}${
                queryObj ? `?${queryString.stringify(queryObj)}` : ''
            }`}
            className={classNames(styles.link, className)}
            style={style}
            passHref={passHref}
            legacyBehavior={legacyBehavior}
        >
            {mounted ? children : <div></div>}
        </Link>
    );
};

export default React.memo(MyLink);
