import React from 'react';
import styles from './responsivesidebar.module.scss';
// import MentorOnImg from '../../../../public/assets/images/svg/mentor-on.svg';
// import MentorOffImg from '../../../../public/assets/images/svg/mentor-off.svg';
// import MyFeedbackOnImg from '../../../../public/assets/images/svg/my-feedback-on.svg';
// import MyFeedbackOffImg from '../../../../public/assets/images/svg/my-feedback-off.svg';
import SwitchCase from '../SwitchCase';
import Layout from './Layout';
import { useRecoilValue } from 'recoil';
import { memberInfoState } from '../../../domains/auth/states/memberInfoState';
import {
    isInvesteeUser,
    isInvestorUser,
    isMentorUser,
} from '../../utils/memberUtil';
import useCheckSignedIn from '../../hooks/useCheckSignedIn';
import classNames from 'classnames';
import MyLink from '../Link/MyLink';
import ProfileButton from './ProfileButton';
import S3StaticImage from '../CustomImage/S3StaticImage';

interface LayoutProps extends React.HTMLAttributes<HTMLElement> {
    current: 'MENTOR' | 'MY-FEEDBACK' | 'MY-PAGE' | 'NOTHING';
}

const ResponsiveLayoutWithSideBar = ({
    children,
    current,
    ...props
}: LayoutProps) => {
    const { userType } = useRecoilValue(memberInfoState);
    const isSignedIn = useCheckSignedIn();
    return (
        <>
            <div className={styles.container} {...props}>
                {isSignedIn && (
                    <Layout className={styles['side-bar']}>
                        <Layout className={styles['btn-container']}>
                            <MyLink pageName="mentorListPage">
                                <Layout className={classNames(styles['btn'])}>
                                    <SwitchCase
                                        value={current}
                                        case={{
                                            MENTOR: (
                                                <S3StaticImage
                                                    key={'/svg/mentor-on.svg'}
                                                    path={'/svg/mentor-on.svg'}
                                                    alt={'calendar'}
                                                />
                                            ),
                                            DEFAULT: (
                                                <S3StaticImage
                                                    key={'/svg/mentor-off.svg'}
                                                    path={'/svg/mentor-off.svg'}
                                                    alt={'calendar'}
                                                />
                                            ),
                                        }}
                                    />
                                </Layout>
                            </MyLink>
                        </Layout>
                        {(isMentorUser(userType) ||
                            isInvesteeUser(userType)) && (
                            <Layout className={styles['btn-container']}>
                                <MyLink
                                    pageName={
                                        isMentorUser(userType)
                                            ? 'mentorMyFeedbackPage'
                                            : 'founderMyFeedbackPage'
                                    }
                                >
                                    <Layout
                                        className={classNames(styles['btn'])}
                                    >
                                        <SwitchCase
                                            value={current}
                                            case={{
                                                'MY-FEEDBACK': (
                                                    <S3StaticImage
                                                        key={
                                                            '/svg/my-feedback-on.svg'
                                                        }
                                                        path={
                                                            '/svg/my-feedback-on.svg'
                                                        }
                                                        alt={'calendar'}
                                                    />
                                                ),
                                                DEFAULT: (
                                                    <S3StaticImage
                                                        key={
                                                            '/svg/my-feedback-off.svg'
                                                        }
                                                        path={
                                                            '/svg/my-feedback-off.svg'
                                                        }
                                                        alt={'calendar'}
                                                    />
                                                ),
                                            }}
                                        />
                                    </Layout>
                                </MyLink>
                            </Layout>
                        )}
                        <Layout className={styles['btn-container']}>
                            <MyLink
                                className={styles['profile-btn-pc']}
                                pageName={
                                    isInvestorUser(userType)
                                        ? 'investorProfilePage'
                                        : 'startUpProfilePage'
                                }
                                queryObj={{ tab: 'Profile' }}
                            >
                                <ProfileButton currentPage={current} />
                            </MyLink>
                            <MyLink
                                className={styles['profile-btn-mo']}
                                pageName={
                                    isInvestorUser(userType)
                                        ? 'investorProfilePage'
                                        : 'startUpProfilePage'
                                }
                            >
                                <ProfileButton currentPage={current} />
                            </MyLink>
                        </Layout>
                    </Layout>
                )}
                {children}
            </div>
        </>
    );
};

export default React.memo(ResponsiveLayoutWithSideBar);
