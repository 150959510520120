import { useCallback } from 'react';
import { useRouter } from 'next/router';
import pages, { PageNameType } from '../../shared/constants/pages';
import { getVisitHistory } from '../../shared/utils/sessionStorageUtils';
import useCheckSignedIn from './useCheckSignedIn';

export default function useCustomHistory() {
    const isSignedIn = useCheckSignedIn();
    const router = useRouter();
    /* 
        특정 page로 이동하는 util. 
        prevPath를 state에 저장하며 해당 state는 이전 페이지 이동에 사용됨.
        replace option이 true인 경우 새로운 state가 기존 state를 완전히 대체함.
        replace option이 false인 경우 기존 state에 새로운 state를 더해줌. 
    */
    const go = useCallback(
        (
            pageName: PageNameType,
            params: string[] = [],
            queryObj = {},
            replace = false,
            mask = false,
            shallow = false,
        ) => {
            let paramString = params.reduce(
                (previousValue, currentValue) =>
                    `${previousValue}/${currentValue}`,
                '',
            );

            if (pageName === 'mainPage' && isSignedIn) {
                pageName = 'mentorListPage';
                paramString = '';
            }
            //여기서 mobile 체크하고 mobile이면 dest에 string붙여서 mobile url로 만들기
            const dest = pages[pageName].url + paramString;
            const currentPathName = router.pathname;

            const destIsCurrentPath = currentPathName === dest;

            const newState = {
                ...queryObj,
            };
            const passingState = newState;

            if (
                (destIsCurrentPath && mask) ||
                (!destIsCurrentPath && mask && replace)
            ) {
                router.replace(
                    {
                        pathname: dest,
                        query: passingState, //next.js에는 location state가 없음. state로 넘기고 dest로 마스킹함.
                    },
                    dest,
                    { shallow },
                );
            } else if (
                (destIsCurrentPath && !mask) ||
                (!destIsCurrentPath && !mask && replace)
            ) {
                router.replace(
                    {
                        pathname: dest,
                        query: passingState,
                    },
                    undefined,
                    { shallow },
                );
            } else if (!destIsCurrentPath && mask && !replace) {
                router.push(
                    {
                        pathname: dest,
                        query: passingState,
                    },
                    dest,
                    { shallow },
                );
            } else if (!destIsCurrentPath && !mask && !replace) {
                router.push(
                    {
                        pathname: dest,
                        query: passingState,
                    },
                    undefined,
                    { shallow },
                );
            }
        },
        [router, isSignedIn],
    );

    /**
     * @params fallbackPath: prevPath가 없을 시 대체될 path
     * @params ignorePrevPath: prevPath를 무시하고 router.back을 할건지
     */
    const { prevPath } = getVisitHistory();
    const routerBack = useCallback(
        (fallbackPath: PageNameType = 'mainPage') => {
            if (typeof window !== 'undefined') {
                if (window.history.length <= 1) {
                    router.replace(pages[fallbackPath].url);
                } else {
                    router.back();
                }
            }
        },
        [router],
    );
    const goBack = useCallback(
        (fallbackPath: PageNameType = 'mainPage', ignorePrevPath = false) => {
            if (prevPath !== '')
                ignorePrevPath
                    ? routerBack(fallbackPath)
                    : router.push(prevPath);
            else router.replace(pages[fallbackPath].url);
        },
        [router, prevPath, routerBack],
    );

    return {
        goBack,
        go,
        router,
        currentUrl: router.asPath.split('?')[0],
        curruntFullUrl: router.asPath,
    };
}
