import { UpdateProfileRequest } from '../../domains/my-page/types';
import { axiosInstance } from '../axiosConfig';
import { UserReferralCodeResponse } from '../types/calendar';
import { MentorInfoResponse, MentorUpdateRequest } from '../types/mentor';
import { CommonResult } from '../types/NetworkResult';

export async function fetchMyMentorInfo(): Promise<MentorInfoResponse> {
    const response = await axiosInstance.get('/mentors/my');
    return response.data;
}

export async function updateMentorProfile(
    request: UpdateProfileRequest<MentorUpdateRequest>,
): Promise<CommonResult> {
    const response = await axiosInstance.patch(
        `/mentors/${request.idx}`,
        request.data,
    );
    return response.data;
}

export async function fetchMentorUserReferralCode(): Promise<UserReferralCodeResponse> {
    const response = await axiosInstance.get('/mentors/referral-code');
    return response.data;
}
export async function createMentorUserReferralCode(): Promise<UserReferralCodeResponse> {
    const response = await axiosInstance.post('/mentors/referral-code');
    return response.data;
}
