import React, { ReactNode } from 'react';

type CaseKey = string | number | symbol | boolean;

interface Props<T extends CaseKey> {
    value: T;
    case: Record<string, ReactNode> & { DEFAULT?: ReactNode };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const SwitchCase = <T extends CaseKey>(props: Props<T>) => {
    const { value, case: caseObj } = props;
    const key = String(value); // value를 문자열로 변환
    return <>{caseObj[key] ?? caseObj.DEFAULT ?? null}</>;
};

export default SwitchCase;
