import React from 'react';
import { useMutation, useQuery } from 'react-query';
import queryKeyWithMember from '../../../../../shared/constants/queryKeyWithMember';
import {
    fetchLinkedInUserInfo,
    signInEmail,
    signInOauth,
    verifyGoogleInfo,
} from '../../../../../api/auth';
import { fetchUserInfo } from '../../../../../api/users';
import { toast } from 'react-toastify';
import {
    OauthInfoResponse,
    SignInEmailRequest,
    SignInOauthRequest,
    SignInResponse,
} from '../../../../../api/types/auth';
import useAuthorizationHandler from '../../../../../shared/hooks/useAuthorizationHandler';
import { CustomQueryOptions } from '../../../../../api/types/CustomQueryOptions';
import { UserInfoResponse } from '../../../../../api/types/users';
import { UseMutationWrapperFnType } from '../../../../../api/types/UseMutationWrapperFnType';
import { OauthCodeRequest } from '../../../../../api/types/auth';
import useCustomHistory from '../../../../../shared/hooks/useCustomHistory';

export const useSignInEmailMutation: UseMutationWrapperFnType<
    SignInResponse,
    SignInEmailRequest
> = (options) => {
    const { handleLogInTasks } = useAuthorizationHandler();
    return useMutation(signInEmail, {
        ...options,
        onSuccess: (response, ...rest) => {
            if (response?.success) {
                handleLogInTasks(response.content.accessToken ?? '');
                if (options?.onSuccess) options.onSuccess(response, ...rest);
            } else {
                toast.error('Please check your email or password.');
            }
        },
    });
};

export const useSignInOauthMutation: UseMutationWrapperFnType<
    SignInResponse,
    SignInOauthRequest
> = (options) => {
    const { handleLogInTasks } = useAuthorizationHandler();
    const { go } = useCustomHistory();
    return useMutation(signInOauth, {
        ...options,
        onSuccess: (response, ...rest) => {
            if (response?.success) {
                handleLogInTasks(response.content.accessToken ?? '');
                if (options?.onSuccess) options.onSuccess(response, ...rest);
            } else {
                const [request] = rest;
                if (
                    response.msg === 'Please check your username or password.'
                ) {
                    /* 해당 email의 user가 없으면 가입 페이지로*/
                    go(
                        'signUpPage',
                        [],
                        {
                            email: request.email,
                            oauthID: request.id,
                            oauthSocialPlatformType: request.type,
                        },
                        false,
                        true,
                    );
                } else {
                    /* 해당 email의 user가 이미 있는데 정보가 불일치 하는 경우 */
                    toast.error('An account already exists with this email');
                }
            }
        },
    });
};

export const useFetchMemberInfo = (
    options: CustomQueryOptions<UserInfoResponse>,
) => {
    return useQuery(
        queryKeyWithMember.myMemberInfo.key,
        fetchUserInfo,
        options,
    );
};

export const useVerifyGoogleByAuthCodeMutation: UseMutationWrapperFnType<
    OauthInfoResponse,
    OauthCodeRequest
> = (options) => {
    return useMutation(verifyGoogleInfo, {
        ...options,
    });
};

export const useFetchLinkedInUserInfoQuery = (
    code: string,
    options: CustomQueryOptions<OauthInfoResponse>,
) => {
    return useQuery(
        queryKeyWithMember.linkedInToken.superKey(code),
        fetchLinkedInUserInfo,
        options,
    );
};
