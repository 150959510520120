export function isInstanceOfAPIError(object: unknown): object is ApiError {
    return (
        object instanceof ApiError &&
        ('redirectUrl' in object ||
            'notFound' in object ||
            'statusCode' in object)
    );
}

export class ApiError extends Error {
    redirectUrl = '';
    notFound = false;
    statusCode = 0;
}

export class InternalServerError extends ApiError {
    statusCode = 500;
    name = 'InternalServerError';
    message = '서버 오류';
    notFound = true;
}

export class MyServerError extends ApiError {
    statusCode = 500;
    name = 'MyServerError';
    message = '서버 오류';
    notFound = true;
    constructor(message: string) {
        super();
        this.message = message;
    }
}

export class NotFoundError extends ApiError {
    statusCode = 404;
    name = 'NotFoundError';
    message = '찾을 수 없습니다.';
    notFound = true;
}

export class ForbiddenError extends ApiError {
    statusCode = 403;
    name = 'ForbiddenError';
    message = '인증처리에 실패했습니다.';
    redirectUrl = '/error';
}

export class AuthError extends ApiError {
    statusCode = 401;
    name = 'AuthError';
    message = '인증되지 않은 사용자입니다.';
    redirectUrl = '/auth';
}
