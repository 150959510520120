import classNames from 'classnames';
import React, {
    ButtonHTMLAttributes,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import styles from './MyButton.module.scss';

interface MyButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: 'primary' | 'secondary' | 'outlined' | 'ghost';
    radius: 'lg' | 'md' | 'sm';
    children: ReactNode;
    className?: string;
}

function MyButton(props: MyButtonProps) {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (!isClicked) {
            setIsClicked(true);
            if (props.onClick) props.onClick(event);
        }
    };

    useEffect(() => {
        if (isClicked) {
            const timer = setTimeout(() => {
                setIsClicked(false);
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isClicked]);

    return (
        <button
            {...props}
            onClick={handleClick}
            className={classNames(styles.btn, props.className, {
                [styles.primary]: props.variant === 'primary',
                [styles.secondary]: props.variant === 'secondary',
                [styles.outlined]: props.variant === 'outlined',
                [styles.ghost]: props.variant === 'ghost',
                [styles['radius-sm']]: props.radius === 'sm',
                [styles['radius-md']]: props.radius === 'md',
                [styles['radius-lg']]: props.radius === 'lg',
            })}
            // disabled={isClicked}
        >
            {props.children}
        </button>
    );
}

MyButton.defaultProps = {
    variant: 'primary',
    radius: 'sm',
};

export default React.memo(MyButton);
