export const VISIT_HISTORY = 'sr_vh';

interface VisitHistory {
    currentPath: string;
    prevPath: string;
}
export const setVisitHistory = (visitHistory: VisitHistory) => {
    if (typeof window !== 'undefined')
        sessionStorage.setItem(VISIT_HISTORY, JSON.stringify(visitHistory));
};

export const getVisitHistory = (): VisitHistory => {
    return (
        typeof window !== 'undefined'
            ? JSON.parse(
                  sessionStorage.getItem(VISIT_HISTORY) ??
                      '{"currentPath": "", "prevPath": ""}',
              )
            : { currentPath: '', prevPath: '' }
    ) as VisitHistory;
};
