import React, { useState, useEffect, useCallback } from 'react';
import {
    ONLY_INVESTEE_PAGE_LIST,
    ONLY_INVESTOR_PAGE_LIST,
    ONLY_MENTOR_PAGE_LIST,
    SIGNED_IN_PAGE_LIST,
    SIGNED_OUT_PAGE_LIST,
} from '../../shared/constants/pages';
import {
    isInvesteeUser,
    isInvestorUser,
    isMentorUser,
} from '../../shared/utils/memberUtil';
import { listHasUrl } from '../../shared/utils/commonUtils';
import useCustomHistory from '../hooks/useCustomHistory';
import { checkSignedIn, getUserTypeCookie } from '../utils/cookieUtils';
import { toast } from 'react-toastify';
import { getVisitHistory, setVisitHistory } from '../utils/sessionStorageUtils';
import Layout from './Layout/Layout';

interface RouteGuardProps {
    children: React.ReactNode;
}
/**
 * client side routing 시 authentication / authority 에 문제가 있다면 다른 페이지로 리디렉션
 */
function RouteGuard({ children }: RouteGuardProps) {
    // const router = useRouter();
    const { go, router, currentUrl } = useCustomHistory();
    //기본값이 false면 ssg로 페이지 생성을 못함.
    const [authorized, setAuthorized] = useState(true);
    const checkAuthentication = useCallback(
        (url: string) => {
            const isSignedIn = checkSignedIn();
            if (!isSignedIn && listHasUrl(SIGNED_IN_PAGE_LIST, url)) {
                go('signInPage', [], { redirect_to: url }, true);
                return false;
            } else if (isSignedIn && listHasUrl(SIGNED_OUT_PAGE_LIST, url)) {
                go('mainPage', [], {}, true);
                return false;
            }
            return true;
        },
        [go],
    );
    const checkUserType = useCallback(
        (url: string) => {
            const userType = getUserTypeCookie();
            if (
                (listHasUrl(ONLY_INVESTEE_PAGE_LIST, url) &&
                    !isInvesteeUser(userType)) ||
                (listHasUrl(ONLY_INVESTOR_PAGE_LIST, url) &&
                    !isInvestorUser(userType)) ||
                (listHasUrl(ONLY_MENTOR_PAGE_LIST, url) &&
                    !isMentorUser(userType))
            ) {
                go('mainPage');
                return false;
            }
            return true;
        },
        [go],
    );
    const checkRouteValid = useCallback(
        (url: string) => {
            const authorized = checkAuthentication(url)
                ? checkUserType(url)
                : false;
            setAuthorized(authorized);
        },
        [checkAuthentication, checkUserType],
    );

    const authCheckForError = useCallback(
        (err, url: string, { shallow }) => checkRouteValid(url),
        [checkRouteValid],
    );
    // const [pageTransitioning, setPageTransitioning] = useState(false);
    useEffect(() => {
        // on initial load
        checkRouteValid(currentUrl);

        // on route change start - hide page content by setting authorized to false
        const hideContent = (url: string) => setAuthorized(false);
        const handleRouteChangeStart = (url: string) => {
            //프로필 페이지 내에서 라우팅 변경 시는 페이지 트랜지션 x
            // if (
            //     currentUrl !== pages.investorProfilePage.url &&
            //     currentUrl !== pages.startUpProfilePage.url
            // )
            //     setPageTransitioning(true);
            toast.dismiss();
            // hideContent(url);
        };
        const setPathHistory = (url: string) => {
            /**
             * 동적 경로 페이지의 경우 query값이 초기에변수형태 그대로 찍힘. 이 경우 실행되지 않도록 함.
             * 예시) /ir/evaluation/[irMatchingIdx] 페이지의 경우 asPath값이 /ir/evaluation/[irMatchingIdx] 그대로 찍힐 수 있고 이 것을 방지하고자 함.
             */
            if (
                Object.values(url).some((value) => value && value.includes('['))
            )
                return;

            const { currentPath } = getVisitHistory();
            if (currentPath !== url) {
                setVisitHistory({
                    prevPath: currentPath,
                    currentPath: url,
                });
            }
        };
        const handleRouteChangeEnd = (url: string) => {
            // console.log('handleRouteChangeEnd');
            // setPageTransitioning(false);
            checkRouteValid(url);
            setPathHistory(url);
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeEnd);
        router.events.on('routeChangeError', authCheckForError);

        // unsubscribe from events in useEffect return function
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeEnd);
            router.events.off('routeChangeError', authCheckForError);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUrl]);

    return (
        <Layout
            // pageTransitioning={pageTransitioning}
            style={{ display: authorized ? 'block' : 'none', width: '100%' }}
        >
            {children}
        </Layout>
    );
}

export default React.memo(RouteGuard);
