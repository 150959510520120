import React from 'react';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';
import queryClient from '../../../../config/queryClient';
import { InvesteeInfo } from '../../../../domains/my-page/types';
import queryKeyWithMember from '../../../constants/queryKeyWithMember';
import { investeeInfoState } from '../../../states/investee/investeeInfoState';

const useInvesteeInfoState = () => {
    const [investeeInfo, setInvesteeInfo] = useRecoilState(investeeInfoState);
    const reset = useResetRecoilState(investeeInfoState);
    const updateInvesteeInfo = useRecoilCallback(
        ({ set }) =>
            (updatedInvesteeInfo: Partial<InvesteeInfo>) => {
                queryClient.removeQueries(queryKeyWithMember.myProfile.key);
                const updated = Object.entries(updatedInvesteeInfo)
                    .filter(([_, value]) => value !== undefined)
                    .reduce((prev, [key, value]) => {
                        prev[key] = value;
                        return prev;
                    }, {});
                set(investeeInfoState, (prev) => ({ ...prev, ...updated }));
            },
    );
    return {
        investeeInfo,
        setInvesteeInfo,
        updateInvesteeInfo,
        reset,
    };
};

export default useInvesteeInfoState;
