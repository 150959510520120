import React from 'react';
import { useRecoilValue } from 'recoil';
import { displayLoadingState } from '../states/displayLoadingState';
import Loading from './Loading';

interface LoadingContainerProps {
    children: React.ReactNode;
}
function LoadingContainer({ children }: LoadingContainerProps) {
    const displayLoading = useRecoilValue(displayLoadingState);
    return (
        <>
            {children}
            {displayLoading && <Loading />}
        </>
    );
}

export default React.memo(LoadingContainer);
