import React, {
    ReactNode,
    Suspense,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useQueryClient } from 'react-query';
import Loading from '../Loading';
import ErrorBoundary from './ErrorBoundary';
import { ErrorFallbackProps } from './ErrorFallback';

export interface AsyncBoundaryProps {
    // eslint-disable-next-line no-unused-vars
    errorFallback(props: ErrorFallbackProps): ReactNode;
    children: ReactNode;
}

const AsyncBoundary = ({ errorFallback, children }: AsyncBoundaryProps) => {
    const queryClient = useQueryClient();
    const resetHandler = useCallback(() => {
        queryClient.clear();
    }, [queryClient]);

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <ErrorBoundary resetQuery={resetHandler} errorFallback={errorFallback}>
            {isClient ? (
                <Suspense fallback={<Loading />}>{children}</Suspense>
            ) : (
                <>{children}</>
            )}
        </ErrorBoundary>
    );
};

export default AsyncBoundary;
