import { axiosInstance } from '../axiosConfig';
import queryString from 'query-string';
import {
    CheckDuplicateEmailResponse,
    OauthInfoResponse,
    ImpVerificationResponse,
    InvestmentInfoResponse,
    InvestmentInfoSingleResponse,
    ResetPasswordRequest,
    SaveInvestmentInfoRequest,
    SignInEmailRequest,
    SignInOauthRequest,
    SignInResponse,
    VerificationEmailCheckRequest,
    VerificationEmailCheckResponse,
    VerificationEmailRequest,
    OauthDuplicateCheckRequest,
} from '../types/auth';
import { CommonResult, SingleResult } from '../types/NetworkResult';
import { OauthCodeRequest } from '../types/auth';

export async function verifyGoogleInfo(
    request: OauthCodeRequest,
): Promise<OauthInfoResponse> {
    const response = await axiosInstance.post('/oauth/google/verify', request);
    return response.data;
}

export async function fetchLinkedInUserInfo({
    queryKey,
}): Promise<OauthInfoResponse> {
    const [, code] = queryKey;
    const response = await axiosInstance.get(
        '/oauth/linked-in/token?code=' + code,
    );
    return response.data;
}

export async function checkGoogleDuplicatedByAuthCode(
    request: OauthCodeRequest,
): Promise<OauthInfoResponse> {
    const response = await axiosInstance.post(
        '/auth/google/duplicate/check',
        request,
    );
    return response.data;
}

export async function checkLinkedInDuplicatedByEmailAndId(
    request: OauthDuplicateCheckRequest,
): Promise<OauthInfoResponse> {
    const response = await axiosInstance.post(
        '/auth/linked-in/duplicate/check',
        request,
    );
    return response.data;
}

export async function signInEmail(
    signInRequest: SignInEmailRequest,
): Promise<SignInResponse> {
    const response = await axiosInstance.post(
        '/auth/token?' + queryString.stringify(signInRequest),
    );
    return response.data;
}

export async function signInOauth(
    signInRequest: SignInOauthRequest,
): Promise<SignInResponse> {
    const response = await axiosInstance.post(
        '/auth/token/oauth?' + queryString.stringify(signInRequest),
    );
    return response.data;
}

export async function fetchCertificationInfo(request: {
    impVerificationCode: string;
}): Promise<ImpVerificationResponse> {
    const response = await axiosInstance.request({
        url: '/auth/imp/verification/request',
        method: 'get',
        params: {
            impVerificationCode: request.impVerificationCode,
        },
    });
    return response.data;
}

export async function checkDuplicatedMemberWithEmail(
    email: string,
): Promise<CheckDuplicateEmailResponse> {
    const response = await axiosInstance.post('/auth/email/duplicate/check', {
        email,
    });
    return response.data;
}

export async function requestEmailCertification(
    verificationEmailRequest: VerificationEmailRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.post(
        '/auth/email/verification/request',
        verificationEmailRequest,
    );
    return response.data;
}

export async function certifyEmailCode(
    verficiationEmailCheckRequest: VerificationEmailCheckRequest,
): Promise<VerificationEmailCheckResponse> {
    const response = await axiosInstance.post(
        '/auth/email/verification/check',
        verficiationEmailCheckRequest,
    );
    return response.data;
}

export async function resetPassword(
    resetPasswordRequest: ResetPasswordRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.put(
        '/auth/email/verification/password',
        resetPasswordRequest,
    );
    return response.data;
}

export async function checkDuplicatedImpVerificationCode(
    impVerificationCode: string,
): Promise<SingleResult<boolean>> {
    const { data } = await axiosInstance.get(
        '/auth/imp/verification/duplicated',
        {
            params: { impVerificationCode },
        },
    );
    return data;
}

export async function checkDIEquality(
    impVerificationCode: string,
): Promise<SingleResult<boolean>> {
    const { data } = await axiosInstance.get(
        '/auth/imp/verification/equals-by-ci',
        {
            params: { impVerificationCode },
        },
    );
    return data;
}

export async function fetchInvestmentInfo({
    queryKey,
}): Promise<InvestmentInfoResponse> {
    const [, name] = queryKey;
    const { data } = await axiosInstance.get(
        `/companies/investments?name=${name}`,
    );
    return data;
}

export async function saveInvestmentInfo(
    saveInvestmentInfoRequest: SaveInvestmentInfoRequest,
): Promise<InvestmentInfoSingleResponse> {
    const response = await axiosInstance.post(
        `/companies/investments`,
        saveInvestmentInfoRequest,
    );
    return response.data;
}
