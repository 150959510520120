import React from 'react';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';
import queryClient from '../../../../config/queryClient';
import { MentorInfo } from '../../../../domains/my-page/types';
import queryKeyWithMember from '../../../constants/queryKeyWithMember';
import { mentorInfoState } from '../../../states/mentor/mentorInfoState';

const useMentorInfoState = () => {
    const [mentorInfo, setMentorInfo] = useRecoilState(mentorInfoState);
    const reset = useResetRecoilState(mentorInfoState);
    const updateMentorInfo = useRecoilCallback(
        ({ set }) =>
            (updatedMentorInfo: Partial<MentorInfo>) => {
                queryClient.removeQueries(queryKeyWithMember.mentorInfo.key);
                const updated = Object.entries(updatedMentorInfo)
                    .filter(([_, value]) => value !== undefined)
                    .reduce((prev, [key, value]) => {
                        prev[key] = value;
                        return prev;
                    }, {});
                set(mentorInfoState, (prev) => ({ ...prev, ...updated }));
            },
    );
    return {
        mentorInfo,
        setMentorInfo,
        updateMentorInfo,
        reset,
    };
};

export default useMentorInfoState;
