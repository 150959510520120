import React, { ReactNode, useEffect, useState } from 'react';

interface DeferredComponentProps {
    delayMs: number;
    children: ReactNode;
    alternativeComponent?: ReactNode;
}

function DeferredComponent({
    delayMs,
    children,
    alternativeComponent,
}: DeferredComponentProps) {
    const [isDeferred, setIsDeferred] = useState(false);
    useEffect(() => {
        // delayMs 지난 후 children Render
        const timeoutId = setTimeout(() => {
            setIsDeferred(true);
        }, delayMs);
        return () => clearTimeout(timeoutId);
    }, [delayMs]);
    if (!isDeferred) {
        return alternativeComponent ? <>{alternativeComponent}</> : null;
    }
    return <>{children}</>;
}

export default DeferredComponent;
