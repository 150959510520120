import { atom } from 'recoil';
import { atomKey } from '../../utils/recoilUtils';
import { MentorInfo } from '../../../domains/my-page/types';

const initState: MentorInfo = {
    idx: 0,
    textMentoringInfo: { amount: 0, activated: false },
    videoMentoringInfo: { amount: 0, activated: false },
    recordingMentoringInfo: { amount: 0, activated: false },
    accountInfo: undefined,
    // feedbackAverageRating: 0,
    // reviewCount: 0,
    phoneNumber: '',
};

export const mentorInfoState = atom<MentorInfo>({
    key: atomKey('mentorInfoState'),
    default: initState,
});
