/** @jsxImportSource @emotion/react */
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { Hydrate, QueryClientProvider } from 'react-query';
import '../public/assets/scss/app/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import queryClient from '../src/config/queryClient';
import { DefaultSeo } from 'next-seo';
import { RecoilRoot } from 'recoil';
import seoConfig from '../seo.config';
import { css } from '@emotion/react';
import 'react-toastify/dist/ReactToastify.css';
import LoadingContainer from '../src/shared/components/LoadingContainer';
import PageLayout from '../src/shared/components/pageLayout/PageLayout';
import RouteGuard from '../src/shared/components/RouteGuard';
import MemberInfo from '../src/shared/components/MemberInfo';
import { ConfigProvider } from 'antd';
import 'swiper/css';
import SwiperCore, {
    Lazy,
    Navigation,
    Scrollbar,
    Autoplay,
    Pagination,
} from 'swiper';
import { initAmplitude } from '../src/shared/utils/amplitudeUtils';
import { GoogleOAuthProvider } from '@react-oauth/google';

SwiperCore.use([Lazy, Scrollbar, Autoplay, Pagination, Navigation]);
function MyApp({
    Component,
    pageProps,
}: AppProps<{ dehydratedState?: unknown }>) {
    const [myQueryClient] = useState(() => queryClient);
    useEffect(() => {
        initAmplitude();
    }, []);
    return (
        <>
            <DefaultSeo {...seoConfig} />
            <div
                id="App"
                css={css({
                    fontFamily: 'Pretendard',
                    color: '#262626',
                })}
            >
                <QueryClientProvider client={myQueryClient}>
                    <Hydrate state={pageProps.dehydratedState}>
                        <RecoilRoot>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Segmented: {
                                            colorBgLayout: '#F5F5F5',
                                            colorTextLabel: '#24292F',
                                        },
                                    },
                                }}
                            >
                                <GoogleOAuthProvider
                                    clientId={`${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`}
                                    onScriptLoadError={() => console.log('')}
                                    onScriptLoadSuccess={() => console.log('')}
                                >
                                    <LoadingContainer>
                                        <PageLayout>
                                            <RouteGuard>
                                                <Component {...pageProps} />
                                            </RouteGuard>
                                            <MemberInfo />
                                        </PageLayout>
                                    </LoadingContainer>
                                </GoogleOAuthProvider>
                            </ConfigProvider>
                        </RecoilRoot>
                    </Hydrate>
                </QueryClientProvider>
            </div>
            <div className="dim">
                <p>Your screen is too small to use our service. </p>
                <p>
                    Please increase the size of your browser window or use a
                    larger device.
                </p>
            </div>
        </>
    );
}

export default MyApp;
