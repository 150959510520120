import {
    UpdateProfileRequest,
    StartupUpdateRequest,
} from '../../domains/my-page/types';
import { axiosInstance } from '../axiosConfig';
import {
    InvesteeResponse,
    SignUpInvesteeRequest,
    SignUpInvesteeWithOAuthRequest,
} from '../types/investee';
import { CommonResult } from '../types/NetworkResult';

export async function fetchMyStartUpManagerProfile(): Promise<InvesteeResponse> {
    const response = await axiosInstance.get('/investees/my');
    return response.data;
}

export async function signUpStartUpWithEmail(
    signUpRequest: SignUpInvesteeRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.post('/investees', signUpRequest);
    return response.data;
}

export async function signUpStartUpOAuth(
    signUpRequest: SignUpInvesteeWithOAuthRequest,
): Promise<CommonResult> {
    const response = await axiosInstance.post(
        '/investees/oauth',
        signUpRequest,
    );
    return response.data;
}

export async function updateStartUpProfile(
    request: UpdateProfileRequest<StartupUpdateRequest>,
): Promise<CommonResult> {
    const response = await axiosInstance.patch(
        `/investees/${request.idx}`,
        request.data,
    );
    return response.data;
}
