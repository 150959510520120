import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY)
        amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
};

export const resetAmplitude = () => {
    amplitude.reset();
};

export const logAmplitude = (
    eventInput: string | amplitude.Types.BaseEvent,
    eventProperties?: Record<string, any>,
    eventOptions?: amplitude.Types.EventOptions,
) => {
    amplitude.track(eventInput, eventProperties, eventOptions);
};

export const setAmplitudeUserId = (userIdx: number) => {
    amplitude.setUserId('feedback-' + String(userIdx));
};
