import { QueryClient } from 'react-query';
import queryKeyWithMember from '../constants/queryKeyWithMember';

export function removeQueryCacheWithMember(queryClient: QueryClient) {
    /* 멤버id와 관련있는 쿼리들 전부 초기화. 다른 id로 로그인하는 경우 때문 */
    for (const queryKey in queryKeyWithMember) {
        queryClient.removeQueries(queryKeyWithMember[queryKey].key);
    }
}

export const passwordType = (showPassword: boolean): 'text' | 'password' => {
    return showPassword ? 'text' : 'password';
};

export const makeAccessTokenValue = (accessToken: string) =>
    `Bearer ${accessToken}`;
