import React from 'react';
import { useRecoilValue } from 'recoil';
import { isSignedInState } from '../../domains/auth/states/isSignedInState';

function useCheckSignedIn() {
    const isSignedIn = useRecoilValue(isSignedInState);
    return isSignedIn === 'SIGNED_IN' ? true : false;
}

export default useCheckSignedIn;
