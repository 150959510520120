import { UserRole } from '../types/users';

const PAGE_LIST = [
    'mainPage',
    'signInPage',
    'signUpPage',
    'findPasswordPage',
    'resetPasswordPage',
    'investorProfilePage',
    'startUpProfilePage',
    'WithdrawalSuccessPage',
    'signUpSuccessPage',
    'mentorListPage',
    'mentorFeedbackEvaluationPage',
    'feedbackApplySuccessPage',
    'evaluationResultPage',
    'termsOfServicePage',
    'privacyPolicyPage',
    'googleAPIDisclosurePage',
    'feedbackServicePoliciesPage',
    'marketingPolicyPage',
    'evaluationPreviewPage',
    'feedbackApplyPage',
    'founderMyFeedbackPage',
    'mentorMyFeedbackPage',
    'mentorCalendarPage',
    'feedbackSettingPage',
    'becomeAMentorPage',
    'paymentSuccessPage',
    'paymentFailPage',
    'recordingResultTempPage',
    'recordingResultMentorPage',
    'recordingResultInvesteePage',
    'recordingPage',
] as const;

export type PageNameType = typeof PAGE_LIST[number];

type PageEntryCondition = 'SIGNED_IN' | 'SIGNED_OUT' | 'WHENEVER';
export type PageInfo = {
    name: string;
    url: string;
    pageEntryCondition: PageEntryCondition;
    userRole: Exclude<UserRole, 'UNREGISTERED_INVESTOR'>[];
    sidebar: 'NONE' | 'MENTOR' | 'MY-FEEDBACK' | 'MY-PAGE' | 'NOTHING';
};

const ALL_MEMBER_ROLES: Exclude<UserRole, 'UNREGISTERED_INVESTOR'>[] = [
    'MENTOR_INVESTOR',
    'INVESTEE',
    'INVESTOR',
];

const pages: Record<PageNameType, PageInfo> = {
    mainPage: {
        name: 'Angelounge',
        url: '/',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    signInPage: {
        name: 'Sign in',
        url: '/auth/sign-in',
        pageEntryCondition: 'SIGNED_OUT',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    signUpPage: {
        name: 'Sign up',
        url: '/auth/sign-up',
        pageEntryCondition: 'SIGNED_OUT',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    findPasswordPage: {
        name: 'Find password',
        url: '/auth/find-password',
        pageEntryCondition: 'SIGNED_OUT',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    resetPasswordPage: {
        name: 'Reset password',
        url: '/auth/reset-password',
        pageEntryCondition: 'SIGNED_OUT',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    investorProfilePage: {
        name: 'MyPage',
        url: '/profile/investor',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTOR'],
        sidebar: 'MY-PAGE',
    },
    startUpProfilePage: {
        name: 'MyPage',
        url: '/profile/startup',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'MY-PAGE',
    },
    WithdrawalSuccessPage: {
        name: 'Withdrawal',
        url: '/bye',
        pageEntryCondition: 'SIGNED_OUT',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    signUpSuccessPage: {
        name: 'Welcome',
        url: '/welcome',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    mentorFeedbackEvaluationPage: {
        name: 'Feedback',
        url: '/feedback/evaluation',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'MY-FEEDBACK',
    },
    evaluationResultPage: {
        name: 'Feedback result',
        url: '/feedback/result',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'MY-FEEDBACK',
    },
    termsOfServicePage: {
        name: 'Terms of service',
        url: '/terms/terms-of-service',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    privacyPolicyPage: {
        name: 'Privacy policy',
        url: '/terms/privacy-policy',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    googleAPIDisclosurePage: {
        name: 'Google API disclosure',
        url: '/terms/google-api-disclosure',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    marketingPolicyPage: {
        name: 'Marketing policy',
        url: '/terms/marketing-policy',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    feedbackServicePoliciesPage: {
        name: 'Marketing policy',
        url: '/terms/feedback-service-policies',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    mentorListPage: {
        name: 'Mentors',
        url: '/mentors',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'MENTOR',
    },
    evaluationPreviewPage: {
        name: 'evaluation preview',
        url: '/feedback/preview',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'NONE',
    },
    feedbackApplyPage: {
        name: 'Feedback apply',
        url: '/feedback/apply',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'MENTOR',
    },
    feedbackApplySuccessPage: {
        name: 'Feedback apply success',
        url: '/feedback/apply/success',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'MENTOR',
    },
    founderMyFeedbackPage: {
        name: 'My feedback',
        url: '/my-feedbacks/founder',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'MY-FEEDBACK',
    },
    mentorMyFeedbackPage: {
        name: 'My feedback',
        url: '/my-feedbacks/mentor',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'MY-FEEDBACK',
    },
    mentorCalendarPage: {
        name: 'Calendar',
        url: '/calendar',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'MY-FEEDBACK',
    },
    feedbackSettingPage: {
        name: 'Feedback setting',
        url: '/feedback/setting',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'NOTHING',
    },
    becomeAMentorPage: {
        name: 'Become a mentor',
        url: '/become-a-mentor',
        pageEntryCondition: 'WHENEVER',
        userRole: ALL_MEMBER_ROLES,
        sidebar: 'NONE',
    },
    paymentSuccessPage: {
        name: 'payment success',
        url: '/payment/success',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'NONE',
    },
    paymentFailPage: {
        name: 'payment fail',
        url: '/payment/fail',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'NONE',
    },
    recordingResultTempPage: {
        name: 'recording result',
        url: '/recording/result/temp',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'MY-FEEDBACK',
    },
    recordingResultMentorPage: {
        name: 'recording result',
        url: '/recording/result/mentor',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'MY-FEEDBACK',
    },
    recordingResultInvesteePage: {
        name: 'recording result',
        url: '/recording/result/investee',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['INVESTEE'],
        sidebar: 'MY-FEEDBACK',
    },
    recordingPage: {
        name: 'recording',
        url: '/recording/feedback',
        pageEntryCondition: 'SIGNED_IN',
        userRole: ['MENTOR_INVESTOR'],
        sidebar: 'MY-FEEDBACK',
    },
};

export const ONLY_MENTOR_PAGE_LIST = Object.values(pages)
    .filter(({ userRole }: PageInfo) =>
        userRole.every((role) => role === 'MENTOR_INVESTOR'),
    )
    .map(({ url }: PageInfo) => url);

export const ONLY_INVESTOR_PAGE_LIST = Object.values(pages)
    .filter(({ userRole }: PageInfo) =>
        userRole.every((role) => role === 'INVESTOR'),
    )
    .map(({ url }: PageInfo) => url);

export const ONLY_INVESTEE_PAGE_LIST = Object.values(pages)
    .filter(({ userRole }: PageInfo) =>
        userRole.every((role) => role === 'INVESTEE'),
    )
    .map(({ url }: PageInfo) => url);

export const SIGNED_IN_PAGE_LIST = Object.values(pages)
    .filter(
        ({ pageEntryCondition }: PageInfo) =>
            pageEntryCondition === 'SIGNED_IN',
    )
    .map(({ url }: PageInfo) => url);

export const SIGNED_OUT_PAGE_LIST = Object.values(pages)
    .filter(
        ({ pageEntryCondition }: PageInfo) =>
            pageEntryCondition === 'SIGNED_OUT',
    )
    .map(({ url }: PageInfo) => url);

export default pages;
