import React from 'react';
import { useQuery } from 'react-query';
import { fetchMyMentorInfo } from '../../../../../api/mentor';
import { CustomQueryOptions } from '../../../../../api/types/CustomQueryOptions';
import { MentorInfoResponse } from '../../../../../api/types/mentor';
import queryKeyWithMember from '../../../../../shared/constants/queryKeyWithMember';

export const useMyMentorProfileQuery = (
    options: CustomQueryOptions<MentorInfoResponse>,
) => useQuery(queryKeyWithMember.mentorInfo.key, fetchMyMentorInfo, options);

export default useMyMentorProfileQuery;
