// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: 'Angel Lounge - Investor Feedback',
    titleTemplate: '%s',
    defaultTitle: 'Angel Lounge - Investor Feedback',
    additionalLinkTags: [
        {
            rel: 'icon',
            href: '/favicon.ico',
        },
        {
            rel: 'manifest',
            href: '/assets/json/manifest.json',
        },
    ],
    additionalMetaTags: [
        {
            name: 'theme-color',
            content: '#000000',
        },
        {
            name: 'title',
            content: 'Angel Lounge - Investor Feedback',
        },
        {
            name: 'description',
            content: 'Angel Lounge is...',
        },
        {
            name: 'viewport',
            content:
                'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1,user-scalable=0',
        },
        {
            name: 'google',
            content: 'notranslate',
        },
        {
            name: 'keywords',
            content:
                '엔젤라운지, Angel Lounge, Angelounge, 엔젤라운지, 스타트업, 투자자, 투자유치, 투자, PR, AC, VC, 피드백',
        },
        {
            name: 'author',
            content: 'Angel Lounge: Investor-Feedback',
        },
    ],
    openGraph: {
        type: 'website',
        locale: 'ko_KR',
        site_name: 'Angel Lounge',
        description: 'Angel Lounge: Investor-Feedback',
        title: 'Angel Lounge',
        images: [
            {
                url: 'https://feedback.angelounge.com/static/image/ogImage.png',
            },
        ],
    },
};
