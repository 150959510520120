import { UserRole } from '../types/users';
import { decodeString, encodeString } from './econdingUtils';

export const setCookie = function (name: string, value: any, expHours = 12) {
    //쿠키 유효시간: 이용 중에 쿠키가 갑자기 날라가서 로그아웃 처리되지 않도록 refresh token 시간 보다 30분 더
    const date = new Date();
    date.setTime(
        date.getTime() + 1 * expHours * 60 * 60 * 1000 + 30 * 60 * 1000,
    );
    const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
    const Cookie = `${name}=${value};Expires=${date.toUTCString()};path=/;domain=${cookieDomain};`;
    if (document) document.cookie = Cookie;
};
export const setCookieExpireAt = function (
    name: string,
    value: any,
    expireAt: string,
) {
    const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;

    const date = new Date(expireAt);
    const Cookie = `${name}=${value};Expires=${date.toUTCString()};path=/;domain=${cookieDomain};`;
    if (document) document.cookie = Cookie;
};
export const getCookie = function (name: string) {
    if (document) {
        document.cookie.split(';');
        const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
        return value ? value[2] : null;
    } else return null;
};

export const deleteCookie = function (name: string) {
    const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
    if (document) {
        document.cookie =
            name +
            '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' +
            cookieDomain +
            ';';
        document.cookie =
            name +
            '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.' +
            cookieDomain +
            ';';
    }
};

export const IS_SIGNED_IN = 'sr_si';
export const USER_TYPE = 'sr_ut';
export const ACCESS_TOKEN = 'sr_at';

export function setSignedInCookie() {
    setCookie(IS_SIGNED_IN, true, 12);
}

export function setUserTypeCookie(userType: UserRole) {
    setCookie(USER_TYPE, encodeString(userType as string), 12);
}

export function removeSignedInCookie() {
    deleteCookie(IS_SIGNED_IN);
}

export function removeUserTypeCookie() {
    deleteCookie(USER_TYPE);
}
/**
 * PLEASE only use in UseEffect
 */
export function checkSignedIn() {
    // toast.success("check signed In: " + getCookie(IS_SIGNED_IN));
    return getCookie(IS_SIGNED_IN) ? true : false;
}

export const getUserTypeCookie = () => {
    const userType = getCookie(USER_TYPE);
    return userType ? (decodeString(userType) as UserRole) : null;
};
