import { Component } from 'react';
import { ErrorType } from '../../types/common';
import { AsyncBoundaryProps } from './AsyncBoundary';
import * as Sentry from '@sentry/nextjs';
import { isInstanceOfAPIError } from '../../constants/error';
import { toast } from 'react-toastify';
import { isBlank } from '../../utils/stringUtils';

interface ErrorBoundaryProps extends AsyncBoundaryProps {
    resetQuery(): void;
}

interface errorState {
    hasError: boolean;
    error: ErrorType;
}

const initialState = { hasError: false, error: null } as errorState;

export default class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    errorState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = initialState;
    }

    static getDerivedStateFromError(error: ErrorType) {
        console.log('getDerivedStateFromError: ' + error);
        if (error?.message) console.log('error message: ' + error.message);
        Sentry.captureException(error);
        return { hasError: true, error: error };
    }

    resetBoundary = () => {
        this.props.resetQuery?.();
        this.setState(initialState);

        if (
            this.state.error &&
            this.state.error.message &&
            this.state.error.message.startsWith('Missing')
        )
            window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            const { errorFallback } = this.props;
            const { error } = this.state;
            return errorFallback({
                error,
                resetBoundary: this.resetBoundary,
            });
        }

        return this.props.children;
    }
}
