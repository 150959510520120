import React from 'react';
import { useQuery } from 'react-query';
import { fetchMyStartUpManagerProfile } from '../../../../api/investee';
import queryKeyWithMember from '../../../../shared/constants/queryKeyWithMember';
import { CustomQueryOptions } from '../../../../api/types/CustomQueryOptions';
import { InvesteeResponse } from '../../../../api/types/investee';

export const useMyStartUpManagerProfileQuery = (
    options: CustomQueryOptions<InvesteeResponse>,
) =>
    useQuery(
        queryKeyWithMember.myProfile.key,
        fetchMyStartUpManagerProfile,
        options,
    );

export default useMyStartUpManagerProfileQuery;
