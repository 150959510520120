import classNames from 'classnames';
import React from 'react';
import SwitchCase from '../SwitchCase';
import Layout from './Layout';
// import ProfileOnImg from '../../../../public/assets/images/svg/profile-on.svg';
// import ProfileOffImg from '../../../../public/assets/images/svg/profile-off.svg';
import styles from './responsivesidebar.module.scss';
import S3StaticImage from '../CustomImage/S3StaticImage';

interface Props {
    currentPage: 'MENTOR' | 'MY-FEEDBACK' | 'MY-PAGE' | 'NOTHING';
}
const ProfileButton = ({ currentPage }: Props) => {
    return (
        <Layout className={classNames(styles['btn'])}>
            <SwitchCase
                value={currentPage}
                case={{
                    'MY-PAGE': (
                        <S3StaticImage
                            key={'/svg/profile-on.svg'}
                            path={'/svg/profile-on.svg'}
                            alt={'profile-on'}
                        />
                    ),
                    DEFAULT: (
                        <S3StaticImage
                            key={'/svg/profile-off.svg'}
                            path={'/svg/profile-off.svg'}
                            alt={'profile-off'}
                        />
                    ),
                }}
            />
        </Layout>
    );
};

export default React.memo(ProfileButton);
